import SearchResultCard from "./search-result-card/search-result-card.component";
import { SearchForReservationResultsContainer } from './search-for-reservations-results.styles'
import { useContext, useEffect } from "react";
import { SearchForReservationContext } from '../../../contexts/search-for-reservation/search-for-reservation.context';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Button, Box, Grow } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SearchForReservationBookingPreview from '../search-for-reservation-booking-preview/search-for-reservation-booking-preview.component';

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

const SearchForReservationResults = () => {

    const { reservationSearchData, searchResults, searchResultsToBook, clearSearchResultsToBook, previewIsShowing, backToSearchUrl, setPreviewIsShowing } = useContext(SearchForReservationContext);
    const displayMultipleDates = Object.keys(searchResults).length == 1 ? false : true;
    const displayMultipleSpaces = reservationSearchData.numberOfSpaces > 1;

    useEffect(() => {

        window.scrollTo(0, 0);

        setPreviewIsShowing(false);
        clearSearchResultsToBook();
    }, []);

    const navigate = useNavigate();


    const closeReservationPreview = () => {
        setPreviewIsShowing(false);
    }

    const showReservationPreview = () => {
        setPreviewIsShowing(true);
    }

    const backToReservationSearch = () => {
        if (backToSearchUrl) {
            navigate(backToSearchUrl);
        } else {
            navigate('/');
        }
    }

    const performAction = () => {
        navigate('/bookings/booking-summary');
    }

    const snackBarAction = (
        <Fragment>
            <Button sx={{ fontWeight: 'bold', color: 'white' }} size="large" onClick={performAction}>
                BOOK
            </Button>
        </Fragment>
    );

    return (
        <SearchForReservationResultsContainer $justifycontent={'center'} $overflow={displayMultipleDates ? 'scroll' : 'visible'} $resultswrap={'wrap'}>
            <Drawer open={previewIsShowing} onClose={closeReservationPreview} anchor="right">
                <SearchForReservationBookingPreview></SearchForReservationBookingPreview>
            </Drawer>
            <Grid sx={{ width: "98%", marginLeft: '1.0vw', marginRight: '1.0vw', marginBottom: '1.0vh' }} container spacing={2}>
                <Grid item xs={4}>
                    <Button onClick={backToReservationSearch} startIcon={<ArrowBackIcon />}>
                        Back To Search
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Box bgcolor={'blue'} sx={{ backgroundColor: 'blue' }}></Box>
                </Grid>
                <Grid item xs={4}>
                    {
                        (displayMultipleDates || displayMultipleSpaces) &&
                        <Box display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end">
                            <Button onClick={showReservationPreview} startIcon={<ShoppingCartCheckoutIcon />}>
                                {`Reservation Cart(${searchResultsToBook.length})`}
                            </Button>
                        </Box>
                    }
                </Grid>
            </Grid>
            {
                Object.keys(searchResults).map((dateKey) => {
                    return <div key={`${dateKey}-container`} className="search-results-for-date-container">
                        <span key={`${dateKey}-header`} className="date-header-lbl">{dateKey}</span>
                        <div key={`${dateKey}-display-container`} className="search-results-display-container">
                            {
                                searchResults[dateKey].map((result) => {
                                    return <SearchResultCard key={`search-result-card-${result.placeHolderId ? result.placeHolderId : result.reservationId}`} searchResult={result} bookSingleSpace={!displayMultipleDates && !displayMultipleSpaces}></SearchResultCard>
                                })
                            }
                        </div>
                    </div>
                })
            }

            <Snackbar
                open={searchResultsToBook.length > 0}
                message={`Selected Spaces ${searchResultsToBook.length}`}
                TransitionComponent={SlideTransition}
                action={snackBarAction}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            />
        </SearchForReservationResultsContainer>
    )

}

export default SearchForReservationResults;