import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext, Fragment } from 'react';
import CalendarTopHeader from './calendar-top-header/calendar-top-header.component';
import Calendar from './calendar/calendar.component';
import { findFacilityBySearchTerm } from "../../../utils/facility-api/facility-service";
import { CalendarSettingsContext } from '../../../contexts/customer/calendar-settings.context';
import CustomerFacilityCalendarNotFound from '../customer-facility-calendar-not-found/customer-facility-calendar-not-found.component';
import CustomerCalendarOffline from '../customer-facility-calendar-offline/customer-facility-calendar-offline.component';
import { Box, Stack } from '@mui/material';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';


const CustomerFacilityCalendar = () => {

    const [facilityInfo, setFacilityInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setFacilityId, reservationsDate, setReservationsDate } = useContext(CalendarSettingsContext);

    const { facilityName } = useParams();

    useEffect(() => {

        const fetchFacilityInfo = async () => {
            setIsLoading(true);
            const facilityResponse = await findFacilityBySearchTerm(facilityName);
            if (facilityResponse.status == 200 && facilityResponse.data.status === 'success') {
                const { facilityInfo } = facilityResponse.data;
                setFacilityInfo(facilityInfo);
                setFacilityId(facilityInfo.facilityId);
            } else {
                setFacilityInfo(null);
                setFacilityId(null);
            }
            setIsLoading(false);
        }

        fetchFacilityInfo();

    }, []);

    const resdateSelected = (date) => {
        setReservationsDate(date);
    }

    return (
        <Stack sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && facilityInfo && facilityInfo.allowWebCalendar &&
                <Fragment>
                    <CalendarTopHeader reservationsDate={reservationsDate} setReservationsDate={resdateSelected} facilityInfo={facilityInfo}></CalendarTopHeader>
                    <Calendar facilityName={facilityName} facilityId={facilityInfo.facilityId} reservationsDate={reservationsDate}></Calendar>
                </Fragment>
            }
            {
                !isLoading && facilityInfo && !facilityInfo.allowWebCalendar &&
                <CustomerCalendarOffline></CustomerCalendarOffline>
            }
            {
                !isLoading && !facilityInfo &&
                <CustomerFacilityCalendarNotFound></CustomerFacilityCalendarNotFound>
            }
        </Stack>
    )
}

export default CustomerFacilityCalendar;