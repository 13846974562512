import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, update, get, child } from "firebase/database";
import { UpdateUserEmailAddress } from '../facility-api/facility-service';

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const saveReservationTimeAdjustment = async (facilityId, resTimeAdjustment) => {
    try {
        const updates = {};
        const facilityRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/reservationTimeAdjustments`);
        updates[`${resTimeAdjustment.id}`] = resTimeAdjustment;
        await update(facilityRef, updates);
    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
}

export const deleteReservationTimeAdjustments = async (facilityId, resTimeAdjustmentIds) => {
    try {
        const updates = {};
        const facilityRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/reservationTimeAdjustments`);
        for(const id of resTimeAdjustmentIds) {
            updates[`${id}`] = null;
        }

        await update(facilityRef, updates);
    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
}

export const getReservationTimeAdjustments = async (facilityId) => {
    let reservationTimeAdjustments = [];
    try {
        const dbRef = ref(getDatabase());
        const facilityUserSnapshot = await get(child(dbRef, `facility-user-accounts/${facilityId}/reservationTimeAdjustments`));
        if (facilityUserSnapshot.exists()) {
            const resTimeAdjustmentsDict = facilityUserSnapshot.val();
            const keys = Object.keys(resTimeAdjustmentsDict);
            for (const key of keys) {
                reservationTimeAdjustments.push(resTimeAdjustmentsDict[key]);
            }
        }

    } catch (error) {
        console.log(error);
        return false;
    }
    return reservationTimeAdjustments;
}

export const saveReservationSettings = async (facilityId, reservationSettings) => {
    try {
        const { notifyOnNewReservations, allowOnlineReservations, notifyOnCancelRes, notificationEmails, notificationPhoneNumbers, membershipsOnly, daysOutForReservations, advanceDaysNoticeForReservations, advanceHoursNoticeForReservations = 0, maxReservationDate } = reservationSettings;
        const updates = {};
        const facilityRef = ref(getDatabase(), `facility-user-accounts/${facilityId}`);

        updates[`notifyOnNewReservations`] = notifyOnNewReservations ? true : false;
        updates[`allowOnlineReservations`] = allowOnlineReservations ? true : false;
        updates[`notifyOnCancelRes`] = notifyOnCancelRes ? true : false;
        updates[`notificationEmails`] = notificationEmails && notificationEmails.length > 0 ? notificationEmails : null;

        // let cleanPhoneNumbers = [];
        // if (notificationPhoneNumbers) {
        //     for (const pNumber of notificationPhoneNumbers) {
        //         cleanPhoneNumbers.push(pNumber.replace(/[^a-zA-Z ]/g, ""));
        //     }
        // }
        updates[`notificationPhoneNumbers`] = notificationPhoneNumbers.length > 0 ? notificationPhoneNumbers : null;
        updates[`membershipsOnly`] = membershipsOnly ? true : false;
        updates[`daysOutForReservations`] = parseInt(daysOutForReservations);
        updates[`advanceDaysNoticeForReservations`] = parseInt(advanceDaysNoticeForReservations);
        updates[`advanceHoursNoticeForReservations`] = parseInt(advanceHoursNoticeForReservations);
        updates[`maxReservationDate`] = maxReservationDate ? maxReservationDate : null;

        await update(facilityRef, updates);
    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
}

export const saveWebCalendarSettings = async (facilityId, calendarSettings) => {
    try {
        const { allowWebCalendar, allowWebCalendarBookings, searchTerm, showReservationTitlesOnWebCalendar, mainFacilityColor, secondaryFacilityColor, showBlackoutTitlesOnWebCalendar, showInternalBookingTitlesOnWebCalendar } = calendarSettings;

        const updates = {};
        const facilityRef = ref(getDatabase(), `facility-user-accounts/${facilityId}`);

        updates[`allowWebCalendar`] = allowWebCalendar ? true : false;
        updates[`allowWebCalendarBookings`] = allowWebCalendarBookings ? true : false;
        updates[`mainFacilityColor`] = mainFacilityColor && mainFacilityColor.length > 0 ? mainFacilityColor : null;
        updates[`secondaryFacilityColor`] = secondaryFacilityColor && secondaryFacilityColor.length > 0 ? secondaryFacilityColor : null;
        updates[`showReservationTitlesOnWebCalendar`] = showReservationTitlesOnWebCalendar ? true : false;
        updates[`showBlackoutTitlesOnWebCalendar`] = showBlackoutTitlesOnWebCalendar ? true : false;
        updates[`showInternalBookingTitlesOnWebCalendar`] = showInternalBookingTitlesOnWebCalendar ? true : false;

        await update(facilityRef, updates);

        const searchTermUpdates = {};
        const searchTermRef = ref(getDatabase(), `facility-search-terms/${facilityId}`);
        searchTermUpdates[`searchTerm`] = searchTerm && searchTerm.length > 0 ? searchTerm : null;
        searchTermUpdates[`facilityId`] = searchTerm && searchTerm.length > 0 ? facilityId : null;

        await update(searchTermRef, searchTermUpdates);

    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
}

export const saveFacilityAreaReorder = async (facilityId, facilityAreas) => {
    try {
        const updates = {};
        const facilityAreasRef = ref(getDatabase(), `facility-user-accounts/${facilityId}/facilityAreas/`);
        for (const fArea of facilityAreas) {
            const { parentAreaId, sortOrder, id } = fArea;
            if (parentAreaId) {
                updates[`${parentAreaId}/subAreas/${id}/sortOrder`] = sortOrder;
            } else {
                updates[`${id}/sortOrder`] = sortOrder;
            }
        }

        await update(facilityAreasRef, updates);

    } catch (error) {
        console.log(error);
        return {
            message: 'An unknown error occurred.  The facility reorder WAS NOT saved.',
            succeeded: false
        };
    }

    return {
        message: "The facility reorder was saved.",
        succeeded: true
    };
}

export const saveFacilityCustomer = async (facilityId, facilityCustomer) => {
    try {

        const { userId, contactInfo, userFirstName, userLastName, userEmailAddress, userPhoneNumber } = { ...facilityCustomer };

        const updateResponse = await UpdateUserEmailAddress(userId, userEmailAddress.trim());
        if (updateResponse && updateResponse.status === 200 && updateResponse.data && updateResponse.data.status === 'success') {

            const facilityCustomerPath = `facility-user-accounts/${facilityId}/customers/${userId}`;
            const facilityCustomerRef = ref(getDatabase(), `${facilityCustomerPath}`);
            set(facilityCustomerRef, facilityCustomer);

            const customerPath = `customer-user-accounts/${userId}`;
            const customerContactInfoRef = ref(getDatabase(), `${customerPath}/contactInfo`);
            set(customerContactInfoRef, contactInfo);

            const userFirstNameRef = ref(getDatabase(), `${customerPath}/userFirstName`);
            set(userFirstNameRef, userFirstName);

            const userLastNameRef = ref(getDatabase(), `${customerPath}/userLastName`);
            set(userLastNameRef, userLastName);

            const userPhoneNumberRef = ref(getDatabase(), `${customerPath}/userPhoneNumber`);
            set(userPhoneNumberRef, userPhoneNumber);

        } else if (updateResponse && updateResponse.status === 200 && updateResponse.data && updateResponse.data.status === 'failed') {
            return {
                message: updateResponse.data.message,
                succeeded: false
            };
        }

    } catch (error) {
        console.log(error);
        return {
            message: 'An unknown error occurred.  The customer profile was not saved.',
            succeeded: false
        };
    }

    return {
        message: "The customer's profile was updated.",
        succeeded: true
    };
}