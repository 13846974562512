import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActions, Stack, TextField } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const StyledTextField = styled(TextField)`
  .MuiInput-input:hover {
    cursor: pointer;
  }
`;

const BirthdayPartyGroupInfoCard = ({ birthdayPartyGroup, facilityName }) => {

    const { userData } = useContext(UserContext);
    const { groupName, description = "", lowestPrice, birthdayParties } = birthdayPartyGroup;
    const { mainTextColor = "#14254C", secondaryTextColor = "#A9A9A9"} = birthdayPartyGroup && ( !userData || ( userData && !userData.isFacilityUserAccount ) ) ? birthdayPartyGroup : {};
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        navigate(`/birthday-parties/${facilityName}/package/${birthdayPartyGroup.searchTerm ? birthdayPartyGroup.searchTerm : birthdayPartyGroup.id}`);

    }
    return (
        <Card onClick={handleClick} sx={{
            width: "20vw", minHeight: {xs: "15vh", sm: "15vh", md: "15vh", lg: "40vh", xl: "45vh" }, display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: `0 3px 10px ${secondaryTextColor}` }
        }}>
            <CardContent sx={{ display: "flex", flexDirection: 'column', flexGrow: '1', alignItems: 'stretch' }}>
                <Typography sx={{ fontSize: '1.5vw' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={mainTextColor} variant="h5" component="div">
                    {groupName}
                </Typography>
                <Stack sx={{ height: '100%' }} spacing={2} justifyContent={'flex-end'} alignItems={'stretch'}>
                    <Box sx={{ width: '100%', marginBottom: '1.0vh', height: '100%' }} display="flex" justifyContent="center" alignItems="center">
                        {/* <StyledTextField sx={{ marginLeft: '1.0vw', marginRight: '1.0vw', width: '100%', fontSize: '0.85vw' }} variant="standard" multiline InputProps={{ readOnly: true }} value={description}></StyledTextField> */}
                        <Typography sx={{ fontSize: '1.0vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={secondaryTextColor} variant="h6" component="div" mt={0}>
                            {description}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '1.25vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} fontWeight={'bold'} variant="h6" component="div" mt={1}>
                        {birthdayParties.length} Parties To Choose From
                    </Typography>
                    <Stack sx={{ height: '100%' }} spacing={2} justifyContent={'flex-end'} alignItems={'stretch'}>
                        <Typography sx={{ fontSize: '1.45vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={mainTextColor} paragraph gutterBottom variant="h5" component="div" mt={0} mb={0}>
                            {`Starting At`}
                        </Typography>
                        <Typography sx={{ fontSize: '1.45vw' }} textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'} color={secondaryTextColor} paragraph gutterBottom variant="h5" component="div" mt={0} mb={0}>
                            {`$${lowestPrice}`}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions>
                <Box sx={{ width: '100%', marginBottom: '1.0vh' }} display="flex" justifyContent="center" alignItems="center">
                    {
                        !userData || (userData && !userData.facilityId) &&
                        <Button sx={{backgroundColor: `${mainTextColor}`}}  variant="contained" onClick={handleClick}>
                            View Parties
                        </Button>
                    }
                </Box>
            </CardActions>
        </Card>
    )

}

export default BirthdayPartyGroupInfoCard;