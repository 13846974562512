import { useEffect, useState } from "react";
import { SEARCH_DAYS_OF_WEEK_LONG } from "../../utils/constants/constants";
import TwoButtonBar from "../two-button-bar/two-button-bar.component";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import { Popover, Box } from "@mui/material";

const DaysOfWeekListPopover = ({ selectedDayOfWeek, dayOfWeekSelected, anchorEl, closeView }) => {

    const [daysOfWeek, setDaysOfWeek] = useState([]);

    useEffect(() => {
        setDaysOfWeek(selectedDayOfWeek ? selectedDayOfWeek : []);
    }, [selectedDayOfWeek]);

    const clear = () => {
        dayOfWeekSelected("CloseView");
    }

    const handleClick = (e) => {
        e.preventDefault();
        const dow = e.currentTarget.dataset.id;

        let newDowArray = [];
        const existingDow = daysOfWeek.find((d) => d === dow);
        if (existingDow) {
            newDowArray = daysOfWeek.filter(d => d !== dow);
        } else {
            newDowArray = [...daysOfWeek, dow];
        }

        setDaysOfWeek(newDowArray);

        if (dayOfWeekSelected) {
            dayOfWeekSelected(newDowArray, false);
        }
    }

    const doneButtonClicked = () => {
        if (dayOfWeekSelected) {
            dayOfWeekSelected(daysOfWeek, true);
        }

        if (closeView) {
            closeView();
        }
    }

    const clearButtonClicked = () => {
        setDaysOfWeek([]);
    }

    const close = () => {
        if (closeView) {
            closeView();
        }
    }

    return (
        <Popover
            id={'days-of-week-list-popover'}
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box m={2}>
                <List>
                    {
                        SEARCH_DAYS_OF_WEEK_LONG.map(dow => {
                            return <ListItem key={dow} divider={true} onClick={handleClick.bind(this)} data-id={dow}>
                                <ListItemText sx={{ color: '#14254C', marginRight: '1.0vw' }} primary={dow}></ListItemText>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={daysOfWeek.includes(dow)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                            </ListItem>
                        })
                    }
                </List>
                <TwoButtonBar button1Text={'Reset'} button1Action={clearButtonClicked} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
            </Box>
        </Popover>
    )
}

export default DaysOfWeekListPopover;