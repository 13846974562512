import styled from 'styled-components';


export const SubAreaColumnHeaderContainer= styled.span`
    grid-column: ${(props) => `${props.$column}`};
    grid-row: 2 / 2;
    display: flex;
    color: #14254C;
    background-color: ${(props) => `${props.$bgColor}`};
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 5px;
    padding-left: 1px;
    border-radius: 1px;
    opacity: 0.85;
    overflow: hidden;
    font-size: ${(props) => `${props.$fontSize}`};
    font-weight: bold;
    margin-bottom: 0.25.rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 2px;
`