import styled from 'styled-components';

export const AreaColumnHeaderContainer = styled.div`
    grid-column: 1 / ${(props) => `${props.$numberofsubareacolumns + 1}`};
    grid-row: 1 / 5;
    display: grid;
    grid-template-columns: repeat(${(props) => `${props.$numberofsubareacolumns}`}, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 1px;
    min-width: 0;
    position: sticky;
    background-color: ${(props) => `${props.$bgColor}`};
    border-radius: 2px;
    `