import { useRef, useContext, useLayoutEffect, useState } from 'react';
import { CalendarOpenReservationContainer, SelectedContainer } from './calendar-open-reservation.styles';
import { CalendarSettingsContext } from '../../../../contexts/customer/calendar-settings.context';
import { SearchForReservationContext } from '../../../../contexts/search-for-reservation/search-for-reservation.context';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const CalendarOpenReservation = ({ facilityArea, openSlot, bgColor, hoursOfOperation, border = `0px solid #DEDEDE10` }) => {

    const { calendarSettings } = useContext(CalendarSettingsContext);
    const { mainFacilityColor } = calendarSettings;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { clickToBookOpenSlot, setClickToBookOpenSlot } = useContext(SearchForReservationContext);

    useLayoutEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let container = useRef(null);
    if (!hoursOfOperation) {
        return null;
    }

    let backgroundColor = "RGBA(255,255,255,0.10)";
    if (bgColor) {
        backgroundColor = bgColor;
    }

    const { subAreas } = facilityArea;

    let columnSpan = '1 / 2';
    if (subAreas) {
        subAreas.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });

        columnSpan = `1 / ${subAreas.length + 1}`

        let sortAreaIndex = 1;
        for (const sArea of subAreas) {
            if (sArea.id === openSlot.facilityArea.id) {
                columnSpan = `${sortAreaIndex} / ${sortAreaIndex}`
                break;
            }
            sortAreaIndex += 1;
        }
    } 
    else {

        const order = `${facilityArea.sortOrder}`.split(".");
        if (order && order.length > 1) {
            columnSpan = `${order[1]} / ${order[1]}`;
        }

    }

    const handleClick = (e) => {

        e.preventDefault();

        const rect = container.current.getBoundingClientRect()
        if (calendarSettings.allowWebCalendarBookings) {
            rect.windowDimensions = windowDimensions;
            openSlot.coordinates = rect;
            let spaceToEnd = windowDimensions.width - rect.x - rect.width;
            if (spaceToEnd >= 500) {
                openSlot.margin = rect.x + rect.width
            } else if (spaceToEnd < 0) {
                if (rect.x < 0) {
                    openSlot.margin = 10;
                } else {
                    let margin = openSlot.margin - rect.x;
                    if (margin < 0) {
                        margin = windowDimensions.width - 525;
                    }
                    openSlot.margin = margin;
                }

            } else {
                openSlot.margin = rect.x - 500;
            }
            openSlot.anchorEl = e.target;
            setClickToBookOpenSlot(openSlot);
        } else {
            alert(`Web calendar reservations are not allowed for this facility.\n\nTo make a reservations download the My Sport Space app from the Apple Store or Google Play.`)
        }
    }

    const rowStartTimesToRowStart = {};
    if (hoursOfOperation) {

        let startTime = hoursOfOperation.startDateTime.clone();
        let endTime = hoursOfOperation.endDateTime.clone();

        let rowStart = 0;
        while (startTime.isBefore(endTime)) {
            //the reservations grid starts in row 4 and 1 hour represents 2 rows in the grid.
            rowStartTimesToRowStart[startTime.format('h A')] = rowStart;
            startTime.add(60, 'minutes');
            rowStart += 4;
        }
    }

    const resStartMinutes = parseInt(openSlot.startDate.format("m"));
    let rowsAdd = 1;
    if (resStartMinutes === 15) {
        rowsAdd += 1
    } else if (resStartMinutes === 30) {
        rowsAdd += 2
    } else if (resStartMinutes === 45) {
        rowsAdd += 3
    }
    const startResInRow = (rowStartTimesToRowStart[openSlot.startDate.format('h A')]) + rowsAdd;
    const resLengthInMinutes = openSlot.endDate.diff(openSlot.startDate, 'minutes');
    const fifteenMinutesIntervals = resLengthInMinutes / 15;
    const endResInRow = startResInRow + fifteenMinutesIntervals;

    return (
        <CalendarOpenReservationContainer key={`open-slot-${openSlot.id}`} ref={container} $border={border} $backgroundcolor={clickToBookOpenSlot && clickToBookOpenSlot.id === openSlot.id ? `#14254C80` : bgColor ? bgColor : "RGBA(255,255,255,0.10)"} $hovercolor={mainFacilityColor ? `${mainFacilityColor}80` : "#14254C80"} $numberofcolumns={'1'} $gridrowstart={`${startResInRow}`} $gridrowend={`${endResInRow}`} $gridcolumn={columnSpan} onClick={handleClick.bind(this)}>
            {
                clickToBookOpenSlot && clickToBookOpenSlot.id === openSlot.id &&
                <SelectedContainer className='selected' $backgroundcolor={clickToBookOpenSlot && clickToBookOpenSlot.id === openSlot.id ? `${mainFacilityColor}80` : bgColor ? bgColor : "RGBA(255,255,255,0.10)"}>

                </SelectedContainer>
            }
        </CalendarOpenReservationContainer>
    )

}

export default CalendarOpenReservation;