import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Divider, Typography, Box } from '@mui/material';
import AreaSelect from './area-select.component';
import { v4 as uuid } from 'uuid';
import { useContext } from 'react';
import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context';

const AddAreaDialog = ({ isOpen = false, addNewArea, close }) => {

    const { facilityAreas } = useContext(FacilityAreaContext);

    const handleClose = () => {
        if (close) {
            close();
        }
    }

    const addMultipleArea = () => {
        addNewArea(true);
    }
    const addSingleArea = () => {
        addNewArea(false);
    }

    const areaSelected = (area) => {

        const areaToCopy = Object.assign({}, area);
        areaToCopy.id = uuid();
        areaToCopy.name = "";
        areaToCopy.sortOrder = facilityAreas.length + 1;
        areaToCopy.searchStatus = 'inactive';
        areaToCopy.isNewArea = true;
        
        const newSubAreasDict = {};
        if (areaToCopy.subAreas){
            const subAreaList = [];
            const subKeys = Object.keys(areaToCopy.subAreas);
            let sortOrderCount = 0.01;
            for (const sKey of subKeys){
                const subArea = areaToCopy.subAreas[sKey];
                const newSubArea = Object.assign({}, subArea);
                newSubArea.id = uuid();
                newSubArea.parentAreaId = areaToCopy.id;
                newSubArea.sortOrder = areaToCopy.sortOrder + sortOrderCount;
                newSubArea.searchStatus = 'inactive';
                newSubArea.isNewArea = true;
                newSubAreasDict[newSubArea.id] = newSubArea;
                subAreaList.push(newSubArea);

                sortOrderCount += 0.01;
            }
            areaToCopy.subAreas = newSubAreasDict;
            areaToCopy.subAreaList = subAreaList;
        }
        addNewArea(false, areaToCopy);
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="add-area-dialog-title"
            aria-describedby="add-area-dialog-description">
            <DialogTitle sx={{ color: '#14254C' }} id="add-area-dialog-title">
                {"Add New Facility Area"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="add-area-dialog-description">
                    What type of area will you be adding?
                </DialogContentText>
                <Typography color={'#14254C'} fontFamily={'Helvetica'} fontWeight={'bold'} variant='subtitle1' mt={2}>Multiple Area</Typography>
                <Typography ml={2} fontWeight={'bold'} fontFamily={'Helvetica'} color={'gray'} >Multiple Areas has one main area that can be split into other areas</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 1: A basketball court with two rentable half courts</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 2: A soccer field where each half of the field can be rented</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 3: A baseball batting cage facilty that can be rented all at once and also rented by the cage</Typography>
                <Typography color={'#14254C'} fontFamily={'Helvetica'} fontWeight={'bold'} variant='subtitle1' mt={2}>Single Area</Typography>
                <Typography ml={2} fontWeight={'bold'} fontFamily={'Helvetica'} color={'gray'} >Single Areas cannot be split into other areas</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 1: A full court basketball court that can only be rented as a full court</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 2: A soccer field that can only be retned as one field</Typography>
                <Typography ml={4} fontFamily={'Helvetica'} color={'gray'} >Example 3: A batting cage that can only be rented as a single cage</Typography>
                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
                <Typography color={'#14254C'} fontFamily={'Helvetica'} fontWeight={'bold'} variant='subtitle1' mt={2}>Copy Existing Area</Typography>
                <Typography ml={2} fontWeight={'bold'} fontFamily={'Helvetica'} color={'gray'} >Select an area to copy</Typography>
                <Box mt={1} ml={4} mr={5}>
                    <AreaSelect areaSelected={areaSelected}></AreaSelect>
                </Box>

                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={addMultipleArea} autoFocus>
                    Add Multiple Area
                </Button>
                <Button variant="contained" onClick={addSingleArea} autoFocus>
                    Add Single Area
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAreaDialog;