import { UserContext } from "../../../contexts/user.context";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MySportSpaceAlert from "../../alert/alert.component";
import { SketchPicker } from 'react-color';
import { saveWebCalendarSettings } from "../../../utils/firebase/facility-firebase-utils";
import { GetCalendarSettings } from '../../../utils/facility-api/facility-service';
import { Stack, Box, Typography, Button, FormControl, FormControlLabel, Switch, Divider } from "@mui/material";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { removeEditLock, getEditLock } from "../../../utils/firebase/firebase.utils";

const hasWhitespace = (str) => {
    return /\s/.test(str);
}

const hasSpecialChars = (str) => {
    const regex = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;
    return regex.test(str);
}

const defaultFormFields = {
    searchTerm: '',
    mainFacilityColor: "#14254C",
    secondaryFacilityColor: "#8B8681",
    allowWebCalendar: false,
    allowWebCalendarBookings: false
}

const formDescription = 'Web Calendar Settings are used to control the appearance of your facility web calendar that the customer sees'

const calendarNameHelpText = 'Set this to be your desired text for your facility url for a web browser.  IE. www.mysportspace.com/reservations/yoursearchurltext.  It must be 1 word (no spaces).  The text must have more than 4 characters.'

const WebCalendarSettings = () => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { searchTerm, mainFacilityColor, secondaryFacilityColor, showReservationTitlesOnWebCalendar, allowWebCalendar, allowWebCalendarBookings, showBlackoutTitlesOnWebCalendar, showInternalBookingTitlesOnWebCalendar } = formFields;
    const [isEditMode, setIsEditMode] = useState(false);
    const { isAdmin, userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [editModeAlert, setEditModeAlert] = useState(null);
    const [showMainColorPicker, setShowMainColorPicker] = useState(false);
    const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
    const [originalFormFields, setOriginalFormFields] = useState(null);
    const [ editLockName ] = useState('WebSettingsLock');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSettings = async (userData) => {
            setIsLoading(true);
            const calendarSettingsResponse = await GetCalendarSettings(userData.facilityId);
            if (calendarSettingsResponse.status === 200) {

                const { calendarSettings } = calendarSettingsResponse.data;
                if (calendarSettings) {
                    setOriginalFormFields(Object.assign({}, calendarSettings));
                    setFormFields(calendarSettings);
                }
            }
            setIsLoading(false);
        }

        if (userData) {
            fetchSettings(userData);
        }

        return () => {
            if (userData){
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    }

    const setShowResTitles = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'showReservationTitlesOnWebCalendar': !showReservationTitlesOnWebCalendar });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setShowBlackoutTitles = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'showBlackoutTitlesOnWebCalendar': !showBlackoutTitlesOnWebCalendar });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setShowInternalBookingTitles = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'showInternalBookingTitlesOnWebCalendar': !showInternalBookingTitlesOnWebCalendar });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setAllowWebCalendar = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'allowWebCalendar': !allowWebCalendar });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setAllowWebCalendarBookings = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'allowWebCalendarBookings': !allowWebCalendarBookings });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const handleMainColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your main color.")
            return;
        }

        setFormFields({ ...formFields, ["mainFacilityColor"]: color.hex });
        // setShowMainColorPicker(false);
    };

    const handleSecondaryColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your secondary color.")
            return;
        }

        setFormFields({ ...formFields, ["secondaryFacilityColor"]: color.hex });
        // setShowSecondaryColorPicker(false);
    };

    const stayInEditMode = () => {
        setEditModeAlert(null);
    }

    const leaveEditMode = () => {
        setFormFields(originalFormFields);
        setIsEditMode(false);
        setEditModeAlert(null);
        removeEditLock(editLockName, userData);
    }

    const cancelPrompt = () => {
        setEditModeAlert("Are you sure you want to cancel editing?  Any unsaved changes will be lost.");
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation settings.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const saveSettings = () => {

        if (!searchTerm || searchTerm.length < 4) {
            setAlertMessage("You must provide calendar search text that is 1 word (no spaces).  The text must have more than 4 characters. ")
            return;
        }
        if (hasWhitespace(searchTerm)) {
            setAlertMessage("The Calendar Url Text cannot contain spaces. ")
            return;
        }

        if (hasSpecialChars(searchTerm)) {
            setAlertMessage("The Calendar Url Text cannot contain special characters. ")
            return;
        }

        setIsLoading(true);
        const saved = saveWebCalendarSettings(userData.facilityId, formFields);
        if (saved) {
            setAlertMessage("Your settings have been saved.");
            setOriginalFormFields(Object.assign({}, formFields));
            setIsEditMode(false);
        } else {
            setAlertMessage("Your settings have NOT been saved.");
        }
        setIsLoading(false);

    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const openMainColorPicker = () => {
        if (isEditMode) {
            setShowSecondaryColorPicker(false);
            setShowMainColorPicker(!showMainColorPicker);
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const openSecondaryColorPicker = () => {
        if (isEditMode) {
            setShowMainColorPicker(false);
            setShowSecondaryColorPicker(!showSecondaryColorPicker);
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const backToSettingsMenu = () => {
        navigate('/facility/settings');
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <MySportSpaceAlert isOpen={editModeAlert ? true : false} title={"Cancel Edit?"} message={editModeAlert} okButtonText={'Exit Edit Mode'} okAction={leaveEditMode} cancelButtonText={"Cancel"} cancelAction={stayInEditMode}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <Stack ml={1} mr={1} sx={{ width: '100%' }} display="flex" alignItems={'center'} >
                <Box marginTop={1} sx={{width: '100%'}} ml={5} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Button onClick={backToSettingsMenu} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Box>
                <Stack display={'flex'}>
                    <Stack sx={{ width: '80%' }} direction={'row'} mt={2} mb={2}>

                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ fontSize: '2.95vh' }} fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="h5">Web Settings</Typography>
                        </Box>
                        <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                            {isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<SaveOutlinedIcon />} id={'create-button'} variant="contained" type="submit" onClick={saveSettings}>Save</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelPrompt}>Cancel</Button>
                                </Stack>
                            }
                            {
                                !isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} variant="contained" onClick={edit}>Edit</Button>
                                </Stack>
                            }
                        </Box>
                    </Stack>
                    <form autoComplete="off" onSubmit={saveSettings}>
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
                            <CustomDisableInput helperText={calendarNameHelpText} InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} required fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="searchTerm" variant="outlined" label="Url Text" type="text" name="searchTerm" value={searchTerm} onChange={handleChange} ></CustomDisableInput>
                            <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                            <Typography sx={{ fontSize: '2.25vh' }} color={"#14254C"} variant={'h6'}>
                                Web Calendar Settings
                            </Typography>
                            <Typography ml={1} sx={{ fontSize: '1.75vh' }} color={"gray"} variant={'subtitle1'}>
                                {formDescription}
                            </Typography>
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="showReservationTitlesOnWebCalendar" checked={showReservationTitlesOnWebCalendar} onChange={setShowResTitles} />} label="Show Reservation Titles" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="showBlackoutTitlesOnWebCalendar" checked={showBlackoutTitlesOnWebCalendar} onChange={setShowBlackoutTitles} />} label="Show Blackout Titles" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="showInternalBookingTitlesOnWebCalendar" checked={showInternalBookingTitlesOnWebCalendar} onChange={setShowInternalBookingTitles} />} label="Show Internal Booking Titles" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="allowWebCalendar" checked={allowWebCalendar} onChange={setAllowWebCalendar} />} label="Allow Web Calendar" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="allowWebCalendarBookings" checked={allowWebCalendarBookings} onChange={setAllowWebCalendarBookings} />} label="Allow Web Calendar Bookings" />
                            <Stack ml={2} mt={2} spacing={4} direction={'row'}>
                                <Stack spacing={4} direction={'row'}>
                                    <Typography sx={{ fontSize: '2.0vh' }} variant="subtitle1" color={"#14254C"}>Main Calendar Color</Typography>
                                    <Box sx={{
                                        height: '25px',
                                        width: '25px',
                                        backgroundColor: `${mainFacilityColor}`,
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        cursor: 'pointer'
                                    }} onClick={openMainColorPicker}>
                                    </Box>
                                    {
                                        showMainColorPicker &&
                                        <div className="color-picker">
                                            <SketchPicker color={mainFacilityColor} onChangeComplete={handleMainColorSelected} ></SketchPicker>
                                        </div>
                                    }
                                </Stack>
                                <Stack spacing={4} direction={'row'}>
                                    <Typography sx={{ fontSize: '2.0vh' }} variant="subtitle1" color={"#14254C"}>Secondary Calendar Color</Typography>
                                    <Box sx={{
                                        height: '25px',
                                        width: '25px',
                                        backgroundColor: `${secondaryFacilityColor}`,
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        cursor: 'pointer'
                                    }} onClick={openSecondaryColorPicker} >
                                    </Box>

                                    {showSecondaryColorPicker &&
                                        <div className="color-picker">
                                            <SketchPicker color={secondaryFacilityColor} onChangeComplete={handleSecondaryColorSelected}></SketchPicker>
                                        </div>
                                    }
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                        </FormControl >
                    </form>
                </Stack>
            </Stack >
        </Box >





    )
}

export default WebCalendarSettings