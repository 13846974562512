import { CalendarOpenReservationContainer } from './calendar-open-reservation.styles';
import { useRef, useContext, useLayoutEffect, useState } from 'react';
import { UserContext } from '../../../../contexts/user.context';
import { useNavigate } from 'react-router-dom';
import { SearchForReservationContext } from '../../../../contexts/search-for-reservation/search-for-reservation.context';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const CalendarOpenReservation = ({ facilityArea, openSlot, bgColor, hoursOfOperation }) => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { clickToBookOpenSlot, setClickToBookOpenSlot } = useContext(SearchForReservationContext);
    const { hasReservationPrivileges } = useContext(UserContext);

    useLayoutEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let container = useRef(null);

    if (!hoursOfOperation) {
        return null;
    }

    // let backgroundColor = "RGBA(20,37,76,0.10)";
    let backgroundColor = "RGBA(255,255,255,0.10)";
    if (bgColor) {
        backgroundColor = bgColor;
    }

    const { subAreas } = facilityArea;
    let columnSpan = '1';
    if (subAreas) {

        let subAreaArr = [];
        for (const subAreaId of Object.keys(subAreas)) {
            const subArea = subAreas[subAreaId];
            subArea.id = subAreaId;
            subAreaArr.push(subArea);
        }

        subAreaArr.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });

        columnSpan = `1 / ${subAreaArr.length + 1}`

        let sortAreaIndex = 1;
        for (const sArea of subAreaArr) {
            if (sArea.id === openSlot.facilityArea.id) {
                columnSpan = `${sortAreaIndex} / ${sortAreaIndex}`
                break;
            }
            sortAreaIndex += 1;
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (hasReservationPrivileges) {
            const rect = container.current.getBoundingClientRect()
            rect.windowDimensions = windowDimensions;
            openSlot.coordinates = rect;
            let spaceToEnd = windowDimensions.width - rect.x - rect.width;
            if (spaceToEnd >= 500){
                openSlot.margin = rect.x + rect.width
            } else if (spaceToEnd < 0){
                if (rect.x < 0){
                    openSlot.margin = 10;
                } else {
                    let margin = openSlot.margin - rect.x;
                    if (margin < 0){
                        margin = windowDimensions.width - 525;
                    }
                    openSlot.margin = margin;
                }
                
            } else {
                openSlot.margin = rect.x - 500;
            }
            openSlot.anchorEl = e.target;
            setClickToBookOpenSlot(openSlot);
        }
    }

    const rowStartTimesToRowStart = {};
    if (hoursOfOperation) {

        let startTime = hoursOfOperation.startDateTime.clone();
        let endTime = hoursOfOperation.endDateTime.clone();
        let rowStart = 0;
        while (startTime.isSameOrBefore(endTime)) {
            rowStartTimesToRowStart[startTime.format('h A')] = rowStart;
            startTime.add(60, 'minutes');
            rowStart += 4;
        }
    }

    const resStartMinutes = parseInt(openSlot.startDate.format("m"));
    let rowsAdd = 1;
    if (resStartMinutes === 15) {
        rowsAdd += 1
    } else if (resStartMinutes === 30) {
        rowsAdd += 2
    } else if (resStartMinutes === 45) {
        rowsAdd += 3
    }
    const startResInRow = (rowStartTimesToRowStart[openSlot.startDate.format('h A')]) + rowsAdd;
    const resLengthInMinutes = openSlot.endDate.diff(openSlot.startDate, 'minutes');
    const fifteenMinutesIntervals = resLengthInMinutes / 15;
    const endResInRow = startResInRow + fifteenMinutesIntervals;

    return (
        <CalendarOpenReservationContainer key={`open-slot-${openSlot.id}`} ref={container} $backgroundcolor={clickToBookOpenSlot && clickToBookOpenSlot.id === openSlot.id ? `#14254C80` : bgColor ? bgColor : "RGBA(255,255,255,0.10)"}  $numberofcolumns={'1'} $gridrowstart={`${startResInRow}`} $gridrowend={`${endResInRow}`} $gridcolumn={columnSpan} onClick={handleClick.bind(this)}>
        </CalendarOpenReservationContainer>
    )

}

export default CalendarOpenReservation;