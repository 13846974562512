import SearchIconAndTextField from "./search-icon-and-text/search-icon-and-text.component";
import calendarImg from "../../assets/calendar_gray.svg";
import clockImg from "../../assets/clock-icon.svg";
import locationImg from "../../assets/location-icon.svg";
import ballsImg from "../../assets/balls-icon.svg";
import SportsAndSubType from "../sports-and-subtype/sports-and-subtype.component";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts/user.context";
import SearchLocationInput from "./search-location-input/search-location-input.component";
import SearchDateInput from "./search-date-input/search-date-input.component";
import SearchTimeRange from './search-time-range/search-time-range.component';
import { facilityReservationSearch } from "../../utils/facility-api/facility-service";
import { ApplicationDataContext } from "../../contexts/application-data-context";
import MySportSpaceAlert from "../alert/alert.component";
import { SearchForReservationContext } from "../../contexts/search-for-reservation/search-for-reservation.context";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import { Stack, Box, Typography } from "@mui/material";


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);


const SearchForReservation = ({ searchFormDirection = 'row', facilityId, sportsOverride, backUrl }) => {

    const navigate = useNavigate();

    const { currentUser, userData } = useContext(UserContext);
    const { sportToSubAreas } = useContext(ApplicationDataContext);
    const { reservationSearchData, setReservationSearchData, setSearchResults, setIsSearchingForReservations, setBackToSearchUrl } = useContext(SearchForReservationContext);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showSports, setShowSports] = useState(false);
    const [showLocation, setShowLocationInput] = useState(false);
    const [showDateInput, setShowDatesInput] = useState(false);
    const [showTimeRangeInput, setShowTimeRangeInput] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [gettingLocation, setGettingLocation] = useState(false);
    const [userHasBeenNotified, setUserHasBeenNotified] = useState(false);

    const getLocationErrored = () => {

        if (!userHasBeenNotified) {
            setAlertMessage({ title: "Location Needed", message: "Your location is needed in order to search for facilities around you.\n\n Please enable location in your browser settings." });
        }

        let request = Object.assign({}, reservationSearchData)
        request.selectedMiles = null;
        setGettingLocation(false);
        setReservationSearchData(request);

    }

    useEffect(() => {
        const locationErrored = () => {
            getLocationErrored();
        }

        const setupGeoLocation = (nav) => {
            if (nav.geolocation) {
                setGettingLocation(true);
                nav.geolocation.getCurrentPosition(getLocationSuccessful, locationErrored);
            }
        }

        const setupUser = (user) => {
            if (user) {
                let request = Object.assign({}, reservationSearchData)
                request.customer = userData;
                setReservationSearchData(request);
            }
        }

        const setupRequestData = (searchData) => {
            if (dayjs(`${(searchData.searchDate).format('YYYY-MM-DD')}`).isBefore(dayjs().startOf('day'))) {
                let request = Object.assign({}, reservationSearchData)
                request.searchDate = dayjs().startOf('day');
                setReservationSearchData(request);
            }
    
            if (dayjs(`${(searchData.searchDate).format('YYYY-MM-DD')} ${searchData.startTime.format('h:mm a')}`, 'YYYY-MM-DD h:mm a').isBefore(dayjs().startOf('hour'))) {
    
                const tenPm = dayjs().startOf('day').add(22, 'hour');
                const startOfHour = dayjs().startOf('hour');
    
                let request = Object.assign({}, searchData)
                request.startTime = startOfHour;
    
                if (startOfHour.add(1, 'hour').isAfter(tenPm)) {
                    request.endTime = dayjs().startOf('hour').add(1, 'hour');
                } else {
                    request.endTime = dayjs().startOf('day').add(22, 'hours');
                }
    
                setReservationSearchData(request);
            }
        }

        setupGeoLocation(navigator);
        setupUser(currentUser);
        setupRequestData(reservationSearchData);

    }, [currentUser, userData])

    const searchForReservation = async () => {

        if (!reservationSearchData.selectedSport) {
            setAlertMessage({ title: "Sport Required", message: "You must select a sport." });
            return;
        }

        if (!reservationSearchData.selectedSubType) {
            setAlertMessage({ title: "Area Type Required", message: "You must enter a area type." });
            return;
        }

        if (!reservationSearchData.selectedMiles && !reservationSearchData.selectedCity && !reservationSearchData.selectedState && !facilityId) {
            setAlertMessage({ title: "Search Location Required", message: "You must enter a search location" });
            return;
        }

        if ((reservationSearchData.selectedCity && !reservationSearchData.selectedState) || (!reservationSearchData.selectedCity && reservationSearchData.selectedState)) {
            setAlertMessage({ title: "Search City and State Required", message: "You must enter a search city and search state." });
            return;
        }

        if (dayjs(`${(reservationSearchData.searchDate).format('YYYY-MM-DD')} ${reservationSearchData.startTime.format('h:mm a')}`, 'YYYY-MM-DD h:mm a').isBefore(dayjs().startOf('hour'))) {
            setAlertMessage({ title: "Search Date Invalid", message: "My Sport Space cannot search past for a past date/time." });
            return;
        }

        if (reservationSearchData.selectedMiles && gettingLocation) {
            setAlertMessage({ title: "Finding Location", message: "Please try your search again." });
            return;
        }

        setIsSearchingForReservations(true)

        const response = await facilityReservationSearch(reservationSearchData, sportToSubAreas, facilityId, null, false, currentLocation ? currentLocation.latitude : 0.0, currentLocation ? currentLocation.longitude : 0.0  );
        if (response.data) {
            const { searchResults, errorMessage, mustPayByInvoice } = response.data;
            if (errorMessage) {
                setSearchResults(searchResults, null);
                setIsSearchingForReservations(false);
                setAlertMessage({ title: "Search Error", message: errorMessage });
            } else if (!searchResults || searchResults.length <= 0) {
                setSearchResults(searchResults, null);
                setIsSearchingForReservations(false);
                setAlertMessage({ title: "No Open Spaces", messag: "No spaces were found for your search.\n\nPlease refine or change your search criteria and try again.", isLocation: true });
            } else {
                setBackToSearchUrl(backUrl);
                setSearchResults(searchResults, mustPayByInvoice ? 'Invoice' : null);
                setIsSearchingForReservations(false);
                navigate('/reservations/search-results');
            }
        } else {
            setIsSearchingForReservations(false);
            setAlertMessage({ title: "No Open Spaces", message: "No My Sport Space facility was found for your search location.  If searching by city and state, please check the spelling or refer your facility to My Sport Space.", isLocation: true });
        }

    }

    const sportWasSelected = () => {
        setShowSports(false);
    }

    const showSportsList = () => {
        setShowSports(true);
    }

    const closeSportsList = () => {
        setShowSports(false);
    }

    const showTheLocation = () => {
        setShowLocationInput(true);
    }
    const closeTheLocation = () => {
        setShowLocationInput(false);
    }

    const getLocationSuccessful = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setGettingLocation(false);
        setCurrentLocation({ latitude, longitude });

    }

    const locationWasSelected = (city, state, miles) => {
        if (miles) {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationSuccessful, getLocationErrored);
            } else {
                setAlertMessage({ title: "Search By Distance Unavailable", message: "Geolocation is not supported by this browser. You must search by City and State" });
            }

            let request = Object.assign({}, reservationSearchData)
            request.selectedMiles = miles;
            request.selectedCity = null;
            request.selectedState = null;
            setReservationSearchData(request);
        } else {

            let request = Object.assign({}, reservationSearchData)
            request.selectedMiles = null;
            request.selectedCity = city;
            request.selectedState = state;
            setReservationSearchData(request);

        }
        setShowLocationInput(false);
    }

    const showDates = () => {
        setShowDatesInput(true);
    }

    const closeDates = () => {
        setShowDatesInput(false);
    }

    const showTimeRange = () => {
        setShowTimeRangeInput(true);
    }

    const closeTimeRange = () => {
        setShowTimeRangeInput(false);
    }

    const dateWasSelected = (searchDate, repeatsUntilDate, repeatsInterval, daysOfWeek, exceptions, closeDates = true) => {

        setShowDatesInput(!closeDates);

        let request = Object.assign({}, reservationSearchData)
        request.searchDate = searchDate;
        request.repeatsUntilDate = repeatsUntilDate;
        request.repeatsInterval = repeatsInterval;
        request.daysOfWeek = daysOfWeek;
        request.exceptionDates = exceptions;

        setReservationSearchData(request);

    }

    const timeRangeWasSelected = (startTime, endTime, duration, closeTime = true) => {

        setShowTimeRangeInput(closeTime);

        let request = Object.assign({}, reservationSearchData)
        request.startTime = startTime;
        request.endTime = endTime;

        let startMinute = startTime.minute();
        if (startMinute !== 0 && startMinute !== 30) {
            if (startMinute > 0 && startMinute < 30) {
                request.startTime = startTime.startOf('hour');
            } else {
                request.startTime = startTime.startOf('hour').add(30, 'minute');
            }
        }

        let endMinute = endTime.minute();
        if (endMinute !== 0 && endMinute !== 30) {
            if (endMinute > 0 && endMinute < 30) {
                request.endTime = endTime.startOf('hour');
            } else {
                request.endTime = endTime.startOf('hour').add(30, 'minute');
            }
        }

        request.reservationLength = duration;

        setReservationSearchData(request);

    }

    const getLocationText = (miles, city, state) => {

        if (miles) {
            return `Within ${miles} miles`;
        } else if (city && state) {
            return `${city}, ${state}`;
        }
        return "Select Location"
    }

    const getDateText = (searchDate, repeatsUntilDate, repeatsInterval, daysOfWeek) => {
        const dateText = searchDate.format("dddd, MMMM D, YYYY");
        const repeatsText = `${repeatsUntilDate ? ` - ${repeatsInterval} - ${repeatsUntilDate.format("MMMM D")}` : ''}`;

        return `${dateText} ${repeatsText}`;
    }

    const getTimeText = (startTime, endTime, duration) => {
        return `${startTime.format("h:mm a")} to ${endTime.format("h:mm a")} - ${duration} hrs`;
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const closeLocationAlert = () => {
        setAlertMessage(null);
        setUserHasBeenNotified(true);
    }

    let titleColor = searchFormDirection === 'row' ? 'white' : '#14254C';
    let titleSize = searchFormDirection === 'row' ? 'h3' : 'h4';

    const { selectedSport, selectedSubType, facilityArea } = reservationSearchData;

    return (
        <Box>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ""} message={alertMessage ? alertMessage.message : ""} okButtonText={'OK'} okAction={alertMessage && !alertMessage.isLocation ? closeAlert : closeLocationAlert} ></MySportSpaceAlert>
            <Stack sx={{ height: '100%', marginTop: '2.0vh', marginLeft: '1.0vw', marginRight: '1.0vw', zIndex: '99999'}} justifyContent={'center'} spacing={2} >
                <Typography sx={{ borderRadius: '5px', marginLeft: '2.0vw', WebkitTextStroke: '1px #000000' }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={titleColor} variant={titleSize} component="div">
                    Find Your Place To Play
                </Typography>
                <Box display={'flex'} justifyContent={'center'} sx={{ backgroundColor: 'white', borderRadius: '5px' }}>
                    <Stack sx={{ marginTop: '1.0vh', marginBottom: '1.0vh', marginLeft: '1.0vw', marginRight: '1.0vw' }} justifyContent={'center'} alignItems={'center'} spacing={1} direction={searchFormDirection}>
                        <SearchIconAndTextField icon={ballsImg} title={selectedSport ? selectedSport : "Sport"} text={facilityArea ? facilityArea.name : selectedSubType ? selectedSubType : "Choose Sport"} closePopup={closeSportsList} showPopUp={showSportsList} listcomponent={<SportsAndSubType facilityId={facilityId} sportWasSelected={sportWasSelected} showSports={showSports} sportsOverride={sportsOverride} />}></SearchIconAndTextField>
                        {!facilityId &&
                            <SearchIconAndTextField icon={locationImg} title={"Location"} text={getLocationText(reservationSearchData.selectedMiles, reservationSearchData.selectedCity, reservationSearchData.selectedState)} closePopup={closeTheLocation} showPopUp={showTheLocation} listcomponent={<SearchLocationInput showLocationInput={showLocation} locationWasSelected={locationWasSelected}></SearchLocationInput>}></SearchIconAndTextField>
                        }
                        <SearchIconAndTextField icon={calendarImg} title={"Date"} text={getDateText(reservationSearchData.searchDate, reservationSearchData.repeatsUntilDate, reservationSearchData.repeatsInterval)} closePopup={closeDates} showPopUp={showDates} listcomponent={<SearchDateInput showDatesInput={showDateInput} dateWasSelected={dateWasSelected}></SearchDateInput>}></SearchIconAndTextField>
                        <SearchIconAndTextField icon={clockImg} title={"Time Range"} text={getTimeText(reservationSearchData.startTime, reservationSearchData.endTime, reservationSearchData.reservationLength)} closePopup={closeTimeRange} showPopUp={showTimeRange} listcomponent={<SearchTimeRange timeRangeWasSelected={timeRangeWasSelected} showTimeRangeInput={showTimeRangeInput}></SearchTimeRange>}></SearchIconAndTextField>
                        <Box justifyContent={'center'} alignContent={'center'}>
                            <Button variant="contained" onClick={searchForReservation}>
                                Search
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default SearchForReservation