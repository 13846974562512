import styled from 'styled-components';

export const CalendarReservationContainer = styled.div`
grid-column: ${(props) => `${props.$gridcolumn}`};
grid-row: ${(props) => `${props.$gridrowstart}`} / ${(props) => `${props.$gridrowend}`};
display: grid;
grid-template-columns: repeat(${(props) => `${props.$numberofcolumns}`}, 1fr);
grid-template-rows: 1fr;
border-top: 1px solid #eaeaea;
`

export const ResDetailDivContainer = styled.div`
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: grid;
    grid-column: ${(props) => `${props.$spancolumn}`};
    grid-row: ${(props) => `${props.$spanrow}`};
    color: #14254C;
    height: 100%;
    width: 100%;
    background-color: white;
    border-left: 5px solid ${(props) => `${props.$paidsdtatuscolor}`};
    border-radius: 5px 5px 5px 5px;
    overflow: scroll;
`

export const ResTitleAndEmojiContainer = styled.div`
    display: flex;
    align-items: center;
    align-content: stretch;
    width: 100%;
    background-color: ${(props) => `${props.$backgroundcolor}`};
`

export const EmojiSpanContainer = styled.span`
    width: 5%;
    margin-left: 0.5vw;
    margin-right: 0.75vw;
`

export const ResTitleSpanContainer = styled.span`
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    color: ${(props) => `${props.$titlecolor}`};
    text-wrap: wrap;
    overflow: scroll;
    padding: 0.1rem;
    width: 95%;
    height: 100%;
    font-size: ${(props) => `${props.$fontSize}`};
    font-weight: semi-bold;

    &:hover .res-preview {
        transition-delay: 1s;
        width: auto;
        height: auto;
        clip: initial;
        opacity: 1;
        visibility: visible;
        background-color: white;
        border-radius: 5px;
      }
`

export const ResPaidStatusSpanContainer = styled.span`
    grid-column: 1;
    grid-row: 1;
    background-color: orange;
    height: 100%;
    width: 100%;
`


export const CalendarSubAreaOpenReservationContainer = styled.div`
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: grid;
    grid-column: ${(props) => `${props.$spancolumn}`};
    grid-row: ${(props) => `${props.$spanrow}`};
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    color: #14254C;
    font-size: 1.0rem;
    height: 100%;
    width: 100%;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    overflow: scroll;

    &:hover {
        border: 2px solid #14254C;
        box-shadow: 0 0 5px 5px #14254C;
    }
`

export const BlackoutTitleSpanContainer = styled.span`
    -ms-overflow-style: none;
    scrollbar-width: none;
    grid-column: 1;
    grid-row: 1;
    color: white;
    font-size: ${(props) => `${props.$fontSize}`};
    background-color: ${(props) => `${props.$backgroundcolor}`};
    text-wrap: wrap;
    overflow: scroll;
    padding: 0.5rem;

    &:hover .res-preview {
        transition-delay: 1s;
        width: auto;
        height: auto;
        clip: initial;
        opacity: 1;
        visibility: visible;
        background-color: white;
        border-radius: 5px;
      }
`