import styled from 'styled-components';

export const MainAreaNameContainer = styled.span`
    grid-column: 1 / ${(props) => `${props.$numberofsubareacolumns + 1}`};
    grid-row: 1 / ${(props) => `${props.$numberofrows + 1}`};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => `${props.$fontSize}`};
    padding-left: 5px;
    padding-right: 5px;
    color: ${(props) => `${props.$color}`};
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
`