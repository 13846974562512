import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import { useContext, useState } from 'react';
import { FacilityReservationSearchResultsContainer } from './facility-reservation-list.styles';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import MySportSpaceAlert from '../../alert/alert.component';
import FacilityReservationBookDialog from '../facility-reservation-book-info/facility-reservatoin-book-dialog.component';
import { CustomNoRowsOverlay } from '../../../utils/data-grid-utils/data-grid-utilities';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const columns = [
  {
    field: 'startDate',
    renderHeader: () => (
      <strong>
        {'Date'}
      </strong>
    ),
    flex: 0.5,
    minWidth: 150,
    valueGetter: (value) => {
      if (!value) {
        return ""
      }

      return moment(value, "YYYY-MM-DD h:mm a").format('ddd, MMM D, YYYY')
    },
  },
  {
    field: 'startTime',
    renderHeader: () => (
      <strong>
        {'Start Time '}
      </strong>
    ),
    flex: 0.5,
    minWidth: 75,
    valueGetter: (value, row) => {
      return moment(row.startDate, "YYYY-MM-DD h:mm a").format('h:mm a')
    },
  },
  {
    field: 'endDate',
    flex: 0.5,
    minWidth: 75,
    renderHeader: () => (
      <strong>
        {'End Time '}
      </strong>
    ),
    valueGetter: (value) => {
      if (!value) {
        return ""
      }

      return moment(value, "YYYY-MM-DD h:mm a").format('h:mm a')
    },
  },
  {
    field: 'areaReserved', minWidth: 125, flex: 1, renderHeader: () => (
      <strong>
        {'Facility Area'}
      </strong>
    ),
  },
  {
    field: 'price',
    renderHeader: () => (
      <strong>
        {'Price'}
      </strong>
    ),
    flex: 0.5,
    minWidth: 75,
    valueGetter: (value) => {
      if (!value) {
        return ""
      }

      return `$${parseFloat(value).toFixed(2)}`
    },
  },
];

function getRowId(row) {
  return row.reservationId;
}




const FacilityReservationNewList = () => {

  const { isSearchingForReservations, searchResults, searchResultsToBook, setSearchResultsToBook, reservationWarnings, setReservationWarnings, resetBookingInput } = useContext(SearchForReservationContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const [ showBookingDialog, setShowBookingDialog] = useState(false);

  const bookReservations = () => {
    if (searchResultsToBook.length <= 0) {
      showAlert("You must select at atleast 1 reservation to book.")
      return;
    }
    setShowBookingDialog(true);
  }

  const showAlert = (title, message) => {
    setAlertMessage({
      title: title,
      message: message
    });
  }

  const closeAlert = () => {
    setAlertMessage(null);
  }

  const clearWarnings = () => {
    setReservationWarnings([]);
}

const closeBookingDialog = () => {
  resetBookingInput();
  setShowBookingDialog(false)
}

  let resultsArray = [];
  if (searchResults) {
    for (const dateKey of Object.keys(searchResults)) {
      resultsArray = [].concat(searchResults[dateKey], resultsArray);
    }
  }

  let warningMessage = '';
  if (reservationWarnings.length > 0) {
    for (const warning of reservationWarnings) {
      warningMessage = `${warningMessage}\n${warning}`
    }
  }

  return (
    <FacilityReservationSearchResultsContainer>
      <FacilityReservationBookDialog open={showBookingDialog} close={closeBookingDialog}></FacilityReservationBookDialog>
      {
        alertMessage &&
        <MySportSpaceAlert key={`fac-list-alert-key`} title={alertMessage.title} message={alertMessage.message} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
      }
      {reservationWarnings && reservationWarnings.length > 0 &&
        <MySportSpaceAlert key={'warning-message-key'} title={'Notice'} message={warningMessage} okButtonText={'OK'} okAction={clearWarnings} ></MySportSpaceAlert>
      }

      {isSearchingForReservations &&
        <LoadingIndicator></LoadingIndicator>
      }
      {!isSearchingForReservations &&
        <Stack sx={{
          height: '100%',
          width: '99%'
        }}>
          <Box sx={{
            height: Object.keys(searchResultsToBook).length > 0 ? '88%' : '100%',
            width: '100%'
          }}>
            <DataGrid sx={{
              font: 'Helvetica', color: '#14254C',
              fontWeight: 400, '--DataGrid-overlayHeight': '500px'
            }} getRowId={getRowId} checkboxSelection
              disableRowSelectionOnClick rows={resultsArray} columns={columns} onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedResults = [];

                if (searchResults) {
                  const searchResultKeys = Object.keys(searchResults)
                  for (const selectedResId of selectedIDs) {
                    for (const key of searchResultKeys) {
                      const searchResult = searchResults[key].find((searchResult) => searchResult.reservationId === selectedResId);
                      if (searchResult) {
                        selectedResults.push(searchResult);
                      }
                    }
                  }
                }

                setSearchResultsToBook(selectedResults);
              }}
              slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
          </Box>
          {!isSearchingForReservations && searchResultsToBook && Object.keys(searchResultsToBook).length > 0 &&
            <Stack mt={5} direction={'row'} spacing={2} justifyContent={'center'} >
              <Button variant="contained" size="large" onClick={bookReservations} >Book Reservations</Button>
            </Stack>
          }
        </Stack>
      }
    </FacilityReservationSearchResultsContainer>
  );
}

export default FacilityReservationNewList