import { TimesColumnContainer, HoursOfOperationContainer, CalendarTimesContainer } from "./times-column.styles";
import { DisplayContext } from "../../contexts/dispaly-options.context";
import { useContext } from "react";
import TimeLogoHeader from "./time-logo-header.component";
import TimeRow from "./time-row.component";
import HoursOfOperationRow from "./hours-of-operation-row.component copy";
import { Box, Stack } from "@mui/material";

const TimesColumn = ({ timeSlots, hoursOfOperation, mainFacilityColor, column = 1 }) => {

    if (!hoursOfOperation) {
        return null;
    }

    const rowStartTimes = [];
    const endTime = hoursOfOperation.endDateTime.clone().endOf('hour').add(1, 'minute');

    if (hoursOfOperation && hoursOfOperation.startDateTime) {
        let startTime = hoursOfOperation.startDateTime.clone().startOf('hour');
        while (startTime.isBefore(endTime)) {
            rowStartTimes.push(startTime.clone());
            startTime.add(15, 'minutes');
        }
    }

    let rowNumber = 0;
    let textColor = "#14254C";
    if (mainFacilityColor) {
        textColor = mainFacilityColor;
    }

    let color = 'white';
    return (
        <Box sx={{ width: '4.0vw', height: '100%', overflow: 'visible', zIndex: '9999'  }}>
            <Stack sx={{ width: '100%', height: '100%', overflow: 'visible', zIndex: '9999' }} direction={'row'}>
                <CalendarTimesContainer $numberofrows={timeSlots}>
                    {
                        rowStartTimes.length > 0 &&
                        rowStartTimes.map((rowTime) => {
                            rowNumber += 1
                            return <TimeRow rowTime={rowTime} key={`time-row-${rowNumber}`} rowNumber={`${rowNumber} / ${rowNumber}`} totalColumns={1} backgroundColor={color} hoursOfOperation={hoursOfOperation}></TimeRow>
                        })
                    }
                </CalendarTimesContainer>
            </Stack>
        </Box>
    )
}

export default TimesColumn