import styled from 'styled-components';

export const CalendarContainer = styled.div`
    position: fixed;
    display: grid;
    grid-template-columns: 50px ${(props) => `${props.$gridcolumnlayout}`};
    grid-template-rows: repeat(${(props) => `${props.$numberofhours}`});
    overflow: scroll;
    border: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 5px;
    top: 18%;
    bottom: 0%;
    right: 0%;
    left: 0%;
    `