import React, { Fragment, useState } from "react";
import MySportSpaceAlert from "../../../alert/alert.component";

const OpenTimeCalendarRow = ({ rowNumber, totalColumns, backgroundColor = 'white', borderColorIn = '#DEDEDE' }) => {

    const [ showMessage, setShowMessage ] = useState(false);

    let borderColor = 'white';
    let borderSize = '0px';
    if (rowNumber % 4 === 0) {
        borderColor = borderColorIn;
        borderSize = '1px';
    }

    const pStyle = {
        gridColumn: `1 / ${totalColumns + 1}`,
        gridRow: `${rowNumber}`,
        backgroundColor: backgroundColor,
        borderBottom: `${borderSize} solid ${borderColor}`,
        height: "100%",
        width: "100%"
    };

    const showAlert = () => {
        setShowMessage(true)
    }

    const closeAlert = () => {
        setShowMessage(false)
    }

    return (
        <Fragment>
            <MySportSpaceAlert isOpen={showMessage} message={"This is outside of the facility's hours of operation and cannot be booked."} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <div onClick={showAlert} style={pStyle}>
            </div>
        </Fragment>

    )
}

export default OpenTimeCalendarRow;
