import { useParams } from 'react-router-dom';
import { useState, useEffect, Fragment, useContext } from 'react';
import { GetFacilityTourInfo } from '../../utils/facility-api/facility-service';
import mssLogo from "../../assets/logo-01.png";
import SearchForReservation from '../search-for-reservation/search-for-reservation.component';
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import FacilityNotFound from '../facility/facility-not-found/facility-not-found.component';
import MySportSpaceLoadingView from '../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { Box, Typography, Stack, ImageList, ImageListItem, ImageListItemBar, Divider } from '@mui/material';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const mainImageStyle = {
    height: '50vh',
    width: '45vw',
    objectFit: 'contain',
    marginTop: '1.0vh'
};

const FacilityInfoView = () => {

    const { facilityId } = useParams();
    const { isSearchingForReservations } = useContext(SearchForReservationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [facilityTourInfo, setFacilityTourInfo] = useState({});

    useEffect(() => {

        const fetchFacilityTourInfo = async (facilityId) => {
            setIsLoading(true);

            const response = await GetFacilityTourInfo(facilityId);
            if (response && response.status == 200 && response.data.status === 'success') {
                setFacilityTourInfo(response.data);
            } else {
                setFacilityTourInfo({});
            }

            setIsLoading(false);
        }

        window.scrollTo(0, 0);
        fetchFacilityTourInfo(facilityId);
    }, [facilityId]);

    // const allAreaImages = [];
    const areaImagesForDisplay = [];
    const { facilityName, facilityLogoUrl, facilityPhotoUrl, facilityAreas, promotionalText, hoursOfOperation, address = {}, cancelPolicies = [], facilityAmenities = [], membershipsOnly = false, advanceDaysNoticeForReservations, advanceHoursNoticeForReservations, daysOutForReservations, hasMemberships = false, sportsOffered } = facilityTourInfo;
    const { streetAddress, state, city, zipCode } = address;
    if (facilityTourInfo && facilityAreas) {
        for (const area of facilityAreas) {
            if (area.imageLocationURL) {
                // allAreaImages.push({
                //     imageUrl: area.imageLocationURL,
                //     id: area.areaId,
                //     name: area.name,
                //     sortOrder: area.sortOrder
                // });

                areaImagesForDisplay.push({
                    imageUrl: area.imageLocationURL,
                    id: area.areaId,
                    name: area.name,
                    sortOrder: area.sortOrder
                });

                if (area.subAreas) {
                    for (const sArea of area.subAreas) {
                        if (sArea.imageLocationURL) {
                            areaImagesForDisplay.push({
                                imageUrl: sArea.imageLocationURL,
                                id: sArea.areaId,
                                name: sArea.name,
                                sortOrder: sArea.sortOrder
                            });
                        }
                    }
                }
            }
        }

        areaImagesForDisplay.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });
    }

    return (
        <Box mt={11} mb={11} ml={10} mr={10}>
            <MySportSpaceLoadingView isOpen={isSearchingForReservations || isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && !facilityName &&
                <FacilityNotFound></FacilityNotFound>
            }
            {
                !isLoading && facilityName &&
                <Stack display={'flex'} alignContent={'center'} alignItems={'center'} spacing={3}>
                    <Box display={'flex'} justifyContent={'flex-start'} >
                        <Stack width={'100%'} spacing={5} mt={2} ml={3} mr={1} justifyContent={'space-evenly'} alignContent={'center'} alignItems={'center'} >
                            <Typography width={'100%'} fontWeight={'bold'} ml={10} mt={2} mb={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h4">
                                {facilityName}
                            </Typography>
                            <Divider sx={{ width: '80%' }} />
                            <Stack direction={'row'} mb={2} spacing={3} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                <img style={mainImageStyle} className="facility-logo-image" src={facilityLogoUrl ? facilityLogoUrl : mssLogo} alt="Logo" loading="lazy" />
                                <ImageList sx={{ width: '50vw', height: '50vh' }} cols={3} >
                                    {
                                        areaImagesForDisplay.map((image) => {
                                            return <ImageListItem key={image.id}>
                                                <img
                                                    srcSet={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format`}
                                                    alt={image.name}
                                                    loading='lazy'
                                                />
                                                <ImageListItemBar
                                                    title={image.name} />
                                            </ImageListItem>
                                        })
                                    }
                                </ImageList>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider sx={{ width: '80%' }} />
                    <Stack ml={5} mr={5} sx={{ width: '100%' }} direction={'row'}>
                        <Stack width={'50%'}>
                            <Typography width={'100%'} fontWeight={'bold'} ml={15} mt={2} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                {streetAddress}
                            </Typography>
                            <Typography width={'100%'} fontWeight={'bold'} ml={15} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                {city}, {state} {zipCode}
                            </Typography>
                            <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '2.0vw', marginRight: '4.0vw' }}></Divider>
                            {
                                promotionalText &&
                                <Fragment>
                                    <Typography mr={2} fontWeight={'bold'} ml={15} mt={2} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {promotionalText}
                                    </Typography>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '2.0vw', marginRight: '4.0vw' }}></Divider>
                                </Fragment>
                            }
                            <Typography width={'100%'} fontWeight={'bold'} ml={15} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                Hours Of Operation
                            </Typography>
                            {
                                hoursOfOperation && hoursOfOperation.map((hop) => {
                                    return <Stack ml={18} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} spacing={2} width={'80%'} direction={'row'} key={hop.dayText} >
                                        <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            {hop.dayText}
                                        </Typography>
                                        <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            {hop.startTime}
                                        </Typography>
                                        <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            {hop.endTime}
                                        </Typography>
                                    </Stack>
                                })
                            }
                            <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '2.0vw', marginRight: '4.0vw' }}></Divider>
                            <Typography ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                {membershipsOnly ? "Members Only Facility" : "Retail Facility"}
                            </Typography>

                            {
                                hasMemberships &&
                                <Typography ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    Memberships are available
                                </Typography>
                            }
                            {
                                advanceDaysNoticeForReservations &&
                                <Typography ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    {`Reservations require ${advanceDaysNoticeForReservations} days advance notice`}
                                </Typography>
                            }
                            {
                                advanceHoursNoticeForReservations &&
                                <Typography ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    {`Reservations require ${advanceHoursNoticeForReservations} hours advance notice`}
                                </Typography>
                            }
                            {
                                daysOutForReservations &&
                                <Typography ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                    {
                                        `Reservations bookings are allowed up to ${moment().add(daysOutForReservations, 'days').format("ddd, MMMM D, YYYY")}`
                                    }
                                </Typography>
                            }

                            {
                                cancelPolicies &&
                                <Fragment>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '2.0vw', marginRight: '4.0vw' }}></Divider>

                                    <Typography width={'100%'} fontWeight={'bold'} ml={15} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Cancellation Policies
                                    </Typography>
                                </Fragment>
                            }
                            {
                                cancelPolicies && cancelPolicies.map((policy) => {
                                    return <Typography key={policy} ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{policy}</Typography>
                                })
                            }

                            {
                                facilityAmenities &&
                                <Fragment>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh', marginLeft: '2.0vw', marginRight: '4.0vw' }}></Divider>

                                    <Typography width={'100%'} fontWeight={'bold'} ml={15} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Facility Includes
                                    </Typography>
                                </Fragment>
                            }
                            {
                                facilityAmenities && facilityAmenities.map((amenenty) => {
                                    return <Typography key={amenenty} ml={18} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{amenenty}</Typography>
                                })
                            }
                        </Stack>
                        <Divider sx={{ height: '100%' }} orientation="vertical"></Divider>
                        <Box sx={{ maxHeight: '50vh', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }} mt={1} mb={4} width={'50%'} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                            <SearchForReservation key={'search-for-reservation-component'} facilityId={facilityId} searchFormDirection='column' sportsOverride={sportsOffered} backUrl={`/facility-tour/${facilityId}`}></SearchForReservation>
                        </Box>
                    </Stack>
                </Stack>
            }
        </Box >
    )

}

export default FacilityInfoView;