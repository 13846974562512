import styled from 'styled-components';

export const AreaColumnContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${(props) => `${props.$numberofcolumns}`}, 1fr);
    grid-template-rows: repeat(${(props) => `${props.$numberofrows}`}, 1fr);
    border-right: 1px solid ${(props) => `${props.$secondaryfacilitycolor}`};
    border-top: 1px solid ${(props) => `${props.$secondaryfacilitycolor}`};
    background-color: white;
    width: 100%;
    height: 100%;
`