import { useContext, useState } from "react";
import { CalendarSettingsContext } from "../../../../contexts/customer/calendar-settings.context";
import dayjs from "dayjs";
import MaterialUIDatePicker from '../../../material-ui/date-picker/material-ui-date-picker.component';
import { Box, Popover, Grid, Stack, Typography, Button, IconButton } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};

const CalendarTopHeader = ({ facilityInfo }) => {

    const { facilityName, facilityLogoUrl } = facilityInfo.facilityAccountInfo;
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const { calendarSettings, reservationsDate, setReservationsDate } = useContext(CalendarSettingsContext);

    const selectReservationsDate = (date) => {
        setReservationsDate(date.toDate());
        setPopOverAnchorEl(null);
    }

    const goForward = () => {
        const dateCopy = new Date(reservationsDate);
        dateCopy.setDate(reservationsDate.getDate() + 1);
        setReservationsDate(dateCopy);
    }

    const goBackward = () => {
        const dateCopy = new Date(reservationsDate);
        dateCopy.setDate(reservationsDate.getDate() - 1);
        setReservationsDate(dateCopy);
    }

    const toggleCalendarIsOpen = (event) => {
        setPopOverAnchorEl(event.currentTarget);
    }

    const closePopover = () => {
        setPopOverAnchorEl(null);
    }

    const dateString = moment(reservationsDate).format("dddd, MMMM D, YYYY");
    const prevDateString = moment(reservationsDate).add(-1, 'day').startOf('day').format("ddd, MMMM D");
    const nextDateString = moment(reservationsDate).add(1, 'day').startOf('day').format("ddd, MMMM D");

    return (
        <Box sx={{ position: 'fixed' }}>
            <Box display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} textAlign={'left'} fontWeight={'bold'} fontFamily={'Helvetica'} color={calendarSettings ? calendarSettings.mainFacilityColor : "black"}>{facilityName}</Typography>
            </Box>
            <Grid alignItems={'center'} justifyContent={'center'} sx={{ width: '98vw', marginLeft: '1.0vw', marginRight: '1.0vw' }} container>
                <Grid item xs={1}>
                    <img style={logoImageStyle} className="facility-logo-image" src={facilityLogoUrl ? facilityLogoUrl : ""} alt="Logo" />
                </Grid>
                <Grid item xs={10}>
                    <Stack sx={{ width: '100%' }} spacing={5} direction={'row'} display={'flex'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Button sx={{ color: `${calendarSettings ? calendarSettings.secondaryFacilityColor : "gray"}`, fontSize: { xs: '1.25vw', sm: '1.0vw', md: '1.0vw', lg: '1.0vw', xl: '0.85vw' } }} onClick={goBackward} startIcon={<ArrowBackIosIcon />}>
                            {prevDateString}
                        </Button>
                        <Typography sx={{ color: `${calendarSettings ? calendarSettings.secondaryFacilityColor : "black"}`, fontSize: { xs: '1.75vw', sm: '1.5vw', md: '1.0vw', lg: '1.15vw', xl: '1.0vw' } }} textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} variant="h6" component="div">
                            {dateString}
                        </Typography>
                        <Button sx={{ color: `${calendarSettings ? calendarSettings.secondaryFacilityColor : "gray"}`, fontSize: { xs: '1.25vw', sm: '1.0vw', md: '1.0vw', lg: '1.0vw', xl: '0.85vw' } }} onClick={goForward} endIcon={<ArrowForwardIosIcon />}>
                            {nextDateString}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={1}>
                    <Stack direction={'row'} display={'flex'} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
                        <img style={logoImageStyle} className="facility-logo-image" src={facilityLogoUrl ? facilityLogoUrl : ""} alt="Logo" />
                        <IconButton
                            size="large"
                            aria-label="calendar"
                            onClick={toggleCalendarIsOpen}
                        >
                            <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: 12, sm: 16, md: 25, lg: 30 } }} />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            <Popover
                id={'customer-calendar-date-popover'}
                open={popoverAnchorEl ? true : false}
                anchorEl={popoverAnchorEl}
                onClose={closePopover}
            >
                <Box mt={1} mb={1} ml={1} mr={1} sx={{ backgroundColr: 'white' }}>
                    <MaterialUIDatePicker labelText="Choose Date:" minDate={dayjs().startOf('day')} onChange={selectReservationsDate} value={dayjs(reservationsDate)} mainColor={calendarSettings.mainFacilityColor} secondaryColor={calendarSettings.secondaryFacilityColor}></MaterialUIDatePicker>
                </Box>
            </Popover>
        </Box>
    );
}

export default CalendarTopHeader