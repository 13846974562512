
import AreaReservationColumn from './area-reservation-column/area-reservation-column.component.jsx'
import { ReservationCalendarContainer, ReservationFlexCalendarContainer } from './reservation-calendar.styles.jsx'
import { Fragment, useContext, useState } from "react";
import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context.jsx';
import { DisplayContext } from '../../../contexts/dispaly-options.context.jsx';
import { FacilityHoursOfOperationContext } from '../../../contexts/facility/facility-hours-of-operation.context.jsx'
import TimesColumn from '../../times-column/times-column.component.jsx'
import LeftSideBar from './left-side-bar/left-side-bar.component.jsx'
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context.jsx';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component.jsx';
import { findHoursOfOperationForDate } from '../../../utils/reservation-utils/reservation-utils.js';
import { UserContext } from '../../../contexts/user.context.jsx';
import ReservationCalendarMenuBar from './reservation-calendar-menu-bar/reservation-calendar-menu-bar.component.jsx';
import Drawer from '@mui/material/Drawer';
import FacilityClickToBook from '../facility-click-to-book/facility-click-to-book.component.jsx';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx';
import AreasCalendarHeader from './areas-calendar-header/areas-calendar-header.component.jsx';
import { Stack, Box, Fab } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


const ReservationCalendar = () => {

    const { hoursOfOperation } = useContext(FacilityHoursOfOperationContext);
    const { isLoadingReservations, reservations } = useContext(FacilityReservationsContext);
    const { calendarDimensions } = useContext(DisplayContext);
    const [showLeftMenu, setShowLeftMenu] = useState(false);
    const { userData } = useContext(UserContext);

    const closeLeftMenu = () => {
        setShowLeftMenu(false);
    }

    const openLeftMenu = () => {
        setShowLeftMenu(true);
    }

    let { facilityAreas } = useContext(FacilityAreaContext);

    if (!hoursOfOperation || isLoadingReservations) {
        return null;
    }

    const facilityTimeZone = userData && userData.facilityAccountInfo ? userData.facilityAccountInfo.timeZone : "America/Chicago"
    const todaysHoursOfOperation = findHoursOfOperationForDate(hoursOfOperation, reservations);

    if (!todaysHoursOfOperation) {
        return null;
    }
    const todaysHoursOfOperationEnd = todaysHoursOfOperation.endDateTime.clone().endOf('hour').add(1, 'minute');
    const todaysHoursOfOperationStart = todaysHoursOfOperation.startDateTime.clone().startOf('hour');
    const numberOfHours = todaysHoursOfOperationEnd.diff(todaysHoursOfOperationStart, 'hours');

    var column = 1;
    for (const fArea of facilityAreas) {
        fArea.column = column;
        column += 1;
    }
    const timeSlots = (numberOfHours * 4);
    let width = 100 * calendarDimensions.zoom_multiplier;
    const preferredWidth = facilityAreas.length * 16;
    if (preferredWidth > (100)) {
        width = preferredWidth;
    }

    const height = 100 * calendarDimensions.zoom_multiplier;
    const reservationStackWidth = width - 4;

    return (
        <Fragment>
            <FacilityClickToBook></FacilityClickToBook>
            <MySportSpaceLoadingView isOpen={isLoadingReservations}></MySportSpaceLoadingView>
            {!isLoadingReservations &&
                <Fragment>

                    <Drawer open={showLeftMenu} onClose={closeLeftMenu} anchor="left">
                        <LeftSideBar closeMenu={closeLeftMenu}></LeftSideBar>
                    </Drawer>
                    <Stack sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                        <ReservationCalendarMenuBar openMenu={openLeftMenu} />
                        <Stack sx={{ overflow: 'scroll' }} position={'fixed'} top={'140px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                            <Stack mb={2} sx={{ width: `${width}vw`, height: `${height}%` }}>
                                <AreasCalendarHeader></AreasCalendarHeader>
                                <Box sx={{ height: '100%', backgroundColor: 'white', overflow: 'visible' }}>
                                    <Stack sx={{ height: `${height}%` }} display={'flex'} direction={'row'}>
                                        <TimesColumn hoursOfOperation={todaysHoursOfOperation} timeSlots={timeSlots} column={1}></TimesColumn>
                                        <Stack direction={'row'} sx={{ width: `${reservationStackWidth}vw` }}>
                                            {
                                                facilityAreas.map((facilityArea) => {
                                                    return <AreaReservationColumn key={facilityArea.id} column={facilityArea.column + 1} timeSlots={timeSlots} facilityArea={facilityArea} hoursOfOperation={todaysHoursOfOperation} facilityTimeZone={facilityTimeZone}></AreaReservationColumn>
                                                })
                                            }
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Fragment>
            }
        </Fragment >
    )

}

export default ReservationCalendar
