import styled from 'styled-components';

export const TimesColumnContainer = styled.div`
display: grid;
grid-column: ${(props) => `${props.$column}`} / ${(props) => `${props.$column}`};
grid-template-columns: 1fr, 2px;
grid-template-rows: repeat(${(props) => `${props.$numberofrows}`}, 1fr);
border-right: 1px solid #7A8BB2;
width: 4.0vw;
background-color: yellow;
`
export const CalendarTimesContainer = styled.div`
height: 100%;
display: grid;
grid-template-rows: repeat(${(props) => `${props.$numberofrows}`}, 1fr);
width: 100%;
z-index: 9999;
`
export const HoursOfOperationContainer = styled.div`
display: grid;
grid-template-rows: repeat(${(props) => `${props.$numberofrows}`}, 1fr);
width: 1.0vw;
overflow: hidden;
height: 100%;
`
