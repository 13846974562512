import { SportsListContainer } from "./sports-and-subtype.styles";
import { ApplicationDataContext } from "../../contexts/application-data-context";
import { Fragment, useContext } from "react";
import TwoButtonBar from "../two-button-bar/two-button-bar.component";
import { TextField } from "@mui/material";
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import ListWithTextInput from "../list-with-text-input/list-with-text-input.component";
import FacilityAreaList from "../facility/facility-area-list/facility-area-list.component";

const SportsAndSubType = ({ facilityId = null, sportWasSelected, showSports, sportsOverride, flexDirection = 'column', width = '25vw', showButtons = true, showShadow = true, spacesText = "Number of Spaces", position = 'absolute', hideNumberOfSpaces = false }) => {

    const { reservationSearchData, setReservationSearchData } = useContext(SearchForReservationContext);
    const { selectedSport, subTypesForSport, selectedSubType, numberOfSpaces, facilityArea } = reservationSearchData;
    const { sportsSupported } = useContext(ApplicationDataContext);

    const clear = () => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.selectedSport = null;
        searchData.selectedSubType = null;
        searchData.numberOfSpaces = 1;
        searchData.facilityArea = null;
        setReservationSearchData(searchData);
    }

    const selectSport = (sport) => {
        let searchData = Object.assign({}, reservationSearchData);
        if (!sport || sport === '') {
            searchData.selectedSport = null;
            searchData.selectedSubType = null;
            searchData.numberOfSpaces = 1;
        } else {
            searchData.selectedSport = sport;
            searchData.selectedSubType = null;
            searchData.numberOfSpaces = numberOfSpaces;
        }
        searchData.facilityArea = null;
        setReservationSearchData(searchData);
    }

    const handleSubTypeClick = (subTypeFromList) => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.selectedSubType = subTypeFromList;
        setReservationSearchData(searchData);
    }
    const selectNumberOfSpaces = (event) => {
        let spaces = event.target.value;
        if (parseInt(spaces) > 8) {
            spaces = 8;
        }

        if (parseInt(spaces) <= 0) {
            spaces = 1;
        }
        let searchData = Object.assign({}, reservationSearchData);
        searchData.numberOfSpaces = spaces;
        setReservationSearchData(searchData);
    }

    const doneButtonClicked = () => {
        if (reservationSearchData.selectedSport && !selectedSubType && !facilityArea) {
            alert('You must select an area type.');
            return;
        }

        if (sportWasSelected) {
            sportWasSelected();
        }
    }

    return (
        <Fragment>
            {
                showSports &&
                <SportsListContainer $position={position} $flexdirection={flexDirection} $width={width} $boxshadow={showShadow ? ' 0 3px 10px rgb(0 0 0 / 0.2)' : 'none'} >
                    <ListWithTextInput variant='outlined' headerText={'Sport'} list={!sportsOverride ? sportsSupported.filter((s) => { return s !== 'Birthday Party Package' }) : sportsOverride.filter((s) => { return s !== 'Birthday Party Package' })} selectFromList={selectSport} selectedItem={selectedSport} allowInput={false} ></ListWithTextInput>
                    {!facilityArea &&
                        <ListWithTextInput variant='outlined' headerText={ selectedSubType ? 'Area Type' : "Choose Area Type"} list={subTypesForSport} selectFromList={handleSubTypeClick} selectedItem={selectedSubType ? selectedSubType : null} allowInput={false} ></ListWithTextInput>
                    }
                    {
                        facilityId &&
                        <FacilityAreaList facilityIdOverride={facilityId} key={'facility-area-list'}></FacilityAreaList>
                    }
                    {
                        !hideNumberOfSpaces &&
                        <TextField sx={{ minWidth: '15%' }} id="numberofspaces" variant="outlined" label={spacesText} type="number" onChange={selectNumberOfSpaces} name="numberofspaces" value={numberOfSpaces}
                            inputProps={{
                                step: "1",
                                max: "8",
                                min: "1"
                            }} ></TextField>
                    }
                    {
                        showButtons &&
                        <div className="button-bar">
                            <div className="buttons">
                                <TwoButtonBar button1Text={'Reset'} button1Action={clear} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
                            </div>
                        </div>
                    }

                </SportsListContainer>
            }
        </Fragment >
    )
}

export default SportsAndSubType;