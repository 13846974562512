import { Button, FormControl, Stack, TextField, Typography, Box, FormLabel, IconButton, Divider, Popover, Switch, Checkbox } from "@mui/material";
import { useState, useEffect, useContext, Fragment, useRef } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchDaysOfWeekList from "../../search-for-reservation/search-days-of-week-list/search-days-of-week-list.component";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { v4 as uuid } from 'uuid';
import { formatReservationLengthOrDuration } from "../../../utils/reservation-utils/reservation-utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceAlert from "../../alert/alert.component";
import validator from 'validator';
import { CreateOrUpdateBirthdayParty, GetBirthdayParty } from "../../../utils/birthday-api/birthday-service";
import { UserContext } from "../../../contexts/user.context";
import { useNavigate } from 'react-router-dom';
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Fab from '@mui/material/Fab';
import BirthdayPartyAreaGroupings from "./birthday-party-area-groupings.component";
import { NoDeciamlFormat, DeciamlFormat } from "../../../utils/text-field-utils/text-field-utils";
import { getDaysOfWeekText } from "../../../utils/date-utilities/date-utilities";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import SelectBirthdayPartyGroup from "./select-birthday-party-group.component";
import { GetBirthdayPartyGroups } from "../../../utils/birthday-api/birthday-service";
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import { SketchPicker } from 'react-color';
import dayjs from "dayjs";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component";
import { SEARCH_DAYS_OF_WEEK_LONG, DAYS_OF_WEEK_SORT_ORDER } from "../../../utils/constants/constants";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

const hasWhitespace = (str) => {
    return /\s/.test(str);
}

const hasSpecialChars = (str) => {
    const regex = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;
    return regex.test(str);
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    font-family: 'Helvetica';
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: #14254C;
    font-size: 1.0rem;
    font-weight: 400;
    outline-color: #A9A9A9;
  `,
);

const defaultFormFields = {
    name: '',
    id: uuid(),
    price: '0.00',
    minCapicity: 1,
    maxCapicity: 25,
    description: '',
    length: 1,
    daysOfWeek: [],
    timeBetween: 0,
    initialDeposit: '0.00',
    refundableDeposit: false,
    ancillaries: [],
    selectedAreaIds: [],
    notificationEmails: [],
    customFields: [],
    emailInstructions: '',
    descriptions: [],
    status: 'inactive'
}

const findById = (list, idToFind) => {
    const existing = list.find(item => item.id === idToFind);
    if (existing) {
        return existing;
    }
    return null;
}

const sortTimeSlots = (timeSlots) => {
    return timeSlots.sort((a1, a2) => {
        if (a1.startTime.isBefore(a2.startTime)) {
            return -1;
        }

        if (a1.startTime.isAfter(a2.startTime)) {
            return 1;
        }

        return 0;
    });
}

const BirthdayPartySetup = ({ birthdayPartyId, close, isModal = false, saveOverride, birthdayGroupId }) => {

    const { userData, isAdmin } = useContext(UserContext);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { name, id, groupName, status, price, minCapicity, maxCapicity, length, timeBetween = 0, daysOfWeek, initialDeposit, refundableDeposit, description, areaGroupings = [], ancillaries, customFields, notificationEmails, emailInstructions, descriptions = [], searchTerm, isDynamicSearch = false, useTimeSlots = false, mainTextColor = "#14254C", secondaryTextColor = "#A9A9A9", timeSlots = [] } = formFields;
    const [showDowList, setShowDowList] = useState(false);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [leaveMessage, setLeaveMessage] = useState(null);
    const [emailFileAttachments, setEmailFileAttachments] = useState([]);
    const [deletedEmailFileAttachments, setDeletedEmailFileAttachments] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(birthdayPartyId ? false : true);
    const [editLockName, setEditLockName] = useState('birthdayParty/editLock');
    const [birthdayPartyGroups, setBirthdayPartyGroups] = useState([]);
    const [birthdayPartyGroupNames, setBirthdayPartyGroupNames] = useState([]);
    const [showMainColorPicker, setShowMainColorPicker] = useState(false);
    const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [timeSlotPopoverAnchorEl, setTimeSlotPopOverAnchorEl] = useState(null);
    const [addTimeSlotPopoverAnchorEl, setAddTimeSlotPopOverAnchorEl] = useState(null);
    const [editTimeSlot, setEditTimeSlot] = useState({
        id: uuid(),
        dow: [].concat(SEARCH_DAYS_OF_WEEK_LONG),
        startTime: dayjs().startOf('hour'),
        endTime: dayjs().startOf('hour').add(1, 'hour')
    });
    const [addTimeSlot, setAddTimeSlot] = useState({
        id: uuid(),
        dow: [].concat(SEARCH_DAYS_OF_WEEK_LONG),
        startTime: dayjs().startOf('hour'),
        endTime: dayjs().startOf('hour').add(1, 'hour')
    });

    const navigate = useNavigate();
    const hiddenInputRef = useRef(null);
    const enterValuesInHiddenInput = () => {
        <hiddenInputRef className="current onclick"></hiddenInputRef>
    };

    const urlChangeHandler = (url, replace = false) => {
        const historyMethod = replace ? "replaceState" : "pushState";
        window.historyMethod = historyMethod;
    };

    useEffect(() => {
        enterValuesInHiddenInput();

        const handlePopState = (event) => {
            event.preventDefault();
            if (isEditMode) {
                setLeaveMessage("Are you sure you want to leave? Any unsaved information will be lost.");
            } else {
                if (close) {
                    close();
                } else {
                    navigate("/");
                }
            }
        };
        window.addEventListener("popstate", handlePopState);
        window.history.pushState({ modalOpened: false }, "");

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [hiddenInputRef, urlChangeHandler]);

    useEffect(() => {

        async function getBirthdayParty(id, birthdayGroupId) {
            if (userData) {
                setIsLoading(true);
                if (id) {
                    const response = await GetBirthdayParty(id, userData.facilityId);
                    if (response && response.status === 200 && response.data && response.data.status === 'success') {

                        const birthdayParty = response.data.birthdayParty;
                        if (birthdayParty.timeSlots) {
                            const newTimeSlots = [];
                            for (const ts of birthdayParty.timeSlots) {
                                let timeSlotCopy = Object.assign({}, ts);
                                timeSlotCopy.startTime = dayjs(ts.startTime);
                                timeSlotCopy.endTime = dayjs(ts.endTime);
                                newTimeSlots.push(timeSlotCopy)
                            }
                            birthdayParty.timeSlots = newTimeSlots;
                        }

                        setFormFields(birthdayParty);
                        if (response.data.emailFileAttachments) {
                            setEmailFileAttachments(response.data.emailFileAttachments);
                        }
                    } else {
                        if (close) {
                            close();
                        }
                    }
                }

                const groupsResponse = await GetBirthdayPartyGroups(userData.facilityId);
                if (groupsResponse && groupsResponse.status === 200 && groupsResponse.data) {
                    const groupNames = [];
                    const reponseGroups = groupsResponse.data.birthdayPartyGroups;
                    for (const group of reponseGroups) {
                        groupNames.push(group.groupName);

                        if (birthdayGroupId && birthdayGroupId === group.id) {
                            setFormFields({ ...formFields, 'groupName': group.groupName, 'groupId': group.id });
                        }
                    }
                    setBirthdayPartyGroupNames(groupNames);
                    setBirthdayPartyGroups(reponseGroups);
                }
                setIsLoading(false);
            }

        }
        getBirthdayParty(birthdayPartyId, birthdayGroupId);

        const editLock = `birthdayParty/${birthdayPartyId}`;
        setEditLockName(editLock);

        return () => {
            if (userData) {
                removeEditLock(editLock, userData);
            }
        }

    }, [userData]);

    const leavePage = () => {
        setLeaveMessage(null);

        if (close) {
            close();
        } else {
            navigate("/");
        }

    };

    const cancelLeavePage = () => {
        setLeaveMessage(null);
    };

    const cancelEdit = () => {
        setLeaveMessage("Are you sure you want to leave? Any unsaved information will be lost.")
    }

    const edit = async () => {

        if (!isAdmin) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to edit.'
            });
        } else {

            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage({ title: "Edit Lock", message: `Another user is currently editing this area.`, type: 'ok' });
            }
        }
    }

    const updateFormFields = (name, value) => {
        setFormFields({ ...formFields, [name]: value });
    }

    const handleSaveDialogClose = () => {
        setShowSaveDialog(false);
    };

    const handleAddDetailsClick = (event) => {
        event.preventDefault();

        const newDescriptions = [...descriptions, {
            id: uuid(),
            title: '',
            details: '',
            sortOrder: descriptions.length + 1
        }]
        setFormFields({ ...formFields, 'descriptions': newDescriptions });
    }

    const handleAddAncillariyClick = () => {
        const newAcillaries = [...ancillaries, {
            id: uuid(),
            name: '',
            description: '',
            price: '0.00',
            taxable: false,
            sortOrder: ancillaries.length + 1
        }]
        setFormFields({ ...formFields, 'ancillaries': newAcillaries });
    }

    const deleteSelectedAncillary = (e) => {
        const ancillaryId = e.currentTarget.dataset.id;
        setFormFields({ ...formFields, 'ancillaries': ancillaries.filter(a => a.id !== ancillaryId) });
    }

    const handleAncillaryChange = (event) => {
        const { name, value, id } = event.target;
        const ancillary = findById(ancillaries, id);
        if (ancillary) {
            let filteredList = ancillaries.filter(anc => anc.id !== id);
            let newList = [...filteredList, { ...ancillary, [name]: value }];
            let sortedList = newList.sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            });
            setFormFields({ ...formFields, 'ancillaries': sortedList });
        }
    }

    const handleTimeSlotChange = (value, id, type) => {
        if (type == 'start_time') {
            editTimeSlot.startTime = value
        } else {
            editTimeSlot.endTime = value
        }

        setEditTimeSlot(editTimeSlot);
    }

    const handleAddTimeSlotChange = (value, id, type) => {
        if (type == 'start_time') {
            addTimeSlot.startTime = value
        } else {
            addTimeSlot.endTime = value
        }

        setEditTimeSlot(addTimeSlot);
    }

    const deleteSelectedDescription = (e) => {
        const descriptionId = e.currentTarget.dataset.id;
        setFormFields({ ...formFields, 'descriptions': descriptions.filter(d => d.id !== descriptionId) });
    }

    const moveUpDescription = (e) => {
        moveDescription(e, 1);
    }

    const moveDownDescription = (e) => {
        moveDescription(e, -1);
    }

    const moveDescription = (e, value) => {
        const descriptionId = e.currentTarget.dataset.id;
        const description = descriptions.filter(d => d.id === descriptionId)[0];
        let newOrderForDescription = description.sortOrder - value;
        if (newOrderForDescription < 0) {
            newOrderForDescription = 0
        }
        const replaceThisDescription = descriptions.filter(d => d.id !== descriptionId && d.sortOrder === newOrderForDescription)[0];
        replaceThisDescription.sortOrder = description.sortOrder

        const otherDescriptions = descriptions.filter(d => d.id !== descriptionId && d.id !== replaceThisDescription.id);

        description.sortOrder = newOrderForDescription;
        otherDescriptions.push(description);
        otherDescriptions.push(replaceThisDescription);

        setFormFields({ ...formFields, 'descriptions': otherDescriptions });
    }

    const handleDescriptionChange = (event) => {
        const { name, value, id } = event.target;
        const desc = findById(descriptions, id);
        if (desc) {
            let filteredList = descriptions.filter(des => des.id !== id);
            let newList = [...filteredList, { ...desc, [name]: value }];
            let sortedList = newList.sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'descriptions': sortedList });
        }
    }

    const handleAncillaryTaxableChange = (event) => {
        const { id } = event.target;
        const ancillary = findById(ancillaries, id);
        if (ancillary) {
            let filteredList = ancillaries.filter(anc => anc.id !== id);
            let sortedList = [...filteredList, { ...ancillary, 'taxable': !ancillary.taxable }].sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'ancillaries': sortedList });
        }
    }

    const handleAddNotificationEmailClick = () => {
        const newNotificationEmails = [...notificationEmails, {
            id: uuid(),
            email: '',
            sortOrder: notificationEmails.length + 1
        }];
        setFormFields({ ...formFields, 'notificationEmails': newNotificationEmails });
    }

    const deleteNotificationEmail = (e) => {
        const emailId = e.currentTarget.dataset.id;
        setFormFields({ ...formFields, 'notificationEmails': notificationEmails.filter(email => email.id !== emailId) });
    }

    const handleNotificationEmailChange = (event) => {
        const { name, value, id } = event.target;

        const email = findById(notificationEmails, id);
        if (email) {
            let filteredList = notificationEmails.filter(email => email.id !== id);
            let sortedList = [...filteredList, { ...email, [name]: value }].sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'notificationEmails': sortedList });
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'length') {
            const lengthInfo = formatReservationLengthOrDuration(value, length, true);
            if (lengthInfo.length) {
                setFormFields({ ...formFields, [name]: lengthInfo.length });
            }
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    }

    const handleRefundableDeposit = () => {
        setFormFields({ ...formFields, 'refundableDeposit': !refundableDeposit });
    }

    const handleAddEmailFileClick = (event) => {
        const newFiles = Array.from(event.target.files);
        for (const file of newFiles) {
            file.source = 'user'
            file.id = uuid();
        }
        setEmailFileAttachments(emailFileAttachments.concat(newFiles));
    }

    const downloadEmailFileAttachment = (e) => {
        const fileId = e.currentTarget.dataset.id;
        const existingEmailFileAttachments = emailFileAttachments.filter(file => file.id === fileId);
        if (existingEmailFileAttachments.length > 0) {
            const emailAttachment = existingEmailFileAttachments[0];
            if (emailAttachment.url) {
                const pdfUrl = emailAttachment.url;
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.download = emailAttachment.name; // specify the filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const deleteEmailFileAttachment = (e) => {
        const fileId = e.currentTarget.dataset.id;
        const existingEmailFileAttachments = emailFileAttachments.filter(file => file.id === fileId);
        if (existingEmailFileAttachments.length > 0) {

            let deleteTheseAttachments = existingEmailFileAttachments.filter(file => file.source === 'cloud_storage');
            setDeletedEmailFileAttachments(deletedEmailFileAttachments.concat(deleteTheseAttachments));
        }

        setEmailFileAttachments(emailFileAttachments.filter(file => file.id !== fileId));
    }

    const handleAddCustomFieldClick = () => {
        const newCustomFields = [...customFields, {
            id: uuid(),
            text: '',
            sortOrder: customFields.length + 1
        }]
        setFormFields({ ...formFields, 'customFields': newCustomFields });
    }

    const deleteCustomField = (e) => {
        const fieldId = e.currentTarget.dataset.id;
        setFormFields({ ...formFields, 'customFields': customFields.filter(field => field.id !== fieldId) });
    }

    const handleCustomFieldChange = (event) => {
        const { name, value, id } = event.target;

        const field = findById(customFields, id);
        if (field) {
            let filteredList = customFields.filter(cf => cf.id !== id);
            let sortedList = [...filteredList, { ...field, [name]: value }].sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'customFields': sortedList });
        }
    }

    const handleCustomFieldTypeChange = (event) => {
        const { id, value } = event.target;
        const field = findById(customFields, id);
        if (field) {
            let filteredList = customFields.filter(f => f.id !== id);
            let sortedList = [...filteredList, { ...field, 'fieldType': value }].sort((a1, a2) => {
                if (a1.sortOrder < a2.sortOrder) {
                    return -1;
                }

                if (a1.sortOrder > a2.sortOrder) {
                    return 1;
                }

                return 0;
            })
            setFormFields({ ...formFields, 'customFields': sortedList });
        }
    }

    const handleDayOfWeekClick = () => {
        setShowDowList(true);
    }

    const dayOfWeekSelected = (dow, closeList = true) => {
        setFormFields({ ...formFields, 'daysOfWeek': dow });
        if (closeList) {
            setShowDowList(false);
        }
    }

    const handleAddTimeSlot = (e) => {
        setAddTimeSlot({
            id: uuid(),
            dow: [].concat(SEARCH_DAYS_OF_WEEK_LONG),
            startTime: dayjs().startOf('hour'),
            endTime: dayjs().startOf('hour').add(1, 'hour')
        })
        setAddTimeSlotPopOverAnchorEl(e.currentTarget);
    }

    const handleEditTimeSlot = (e) => {
        const timeSlotId = e.currentTarget.dataset.id;
        const foundTimeSlot = timeSlots.filter(f => f.id === timeSlotId);

        if (foundTimeSlot && foundTimeSlot.length > 0) {
            setEditTimeSlot(foundTimeSlot[0]);
        }
        setTimeSlotPopOverAnchorEl(e.currentTarget);
    }

    const deleteSelectedTimeSlot = (e) => {

        const timeSlotId = e.currentTarget.dataset.id;
        setFormFields({ ...formFields, 'timeSlots': timeSlots.filter(ts => ts.id !== timeSlotId) });

    }

    const addTimeSlots = (e) => {

        const addTheseTimeSlots = [];
        for (const dow of addTimeSlot.dow) {
            addTheseTimeSlots.push({
                id: uuid(),
                dow: dow,
                startTime: dayjs(addTimeSlot.startTime),
                endTime: dayjs(addTimeSlot.endTime),
            })
        }

        const newTimeSlots = timeSlots.concat(addTheseTimeSlots);
        setFormFields({ ...formFields, 'timeSlots': sortTimeSlots(newTimeSlots) });
        setAddTimeSlotPopOverAnchorEl(null);
        setAddTimeSlot({});
    }

    const updateTimeSlot = (e) => {

        if (editTimeSlot.endTime.isSameOrBefore(editTimeSlot.startTime)) {
            setAlertMessage({
                title: 'Invalid End Time',
                message: 'End Time must be after Start Time.'
            });
            return;
        }

        if (editTimeSlot.startTime.isSameOrAfter(editTimeSlot.endTime)) {
            setAlertMessage({
                title: 'Invalid Start Time',
                message: 'Start Time must be before End Time.'
            });
            return;
        }

        if (!timeSlots) {
            setFormFields({ ...formFields, 'timeSlots': [editTimeSlot] });
        } else {
            const filteredTimeSlots = timeSlots.filter(ts => ts.id !== editTimeSlot.id);
            const newTimeSlots = [...filteredTimeSlots, editTimeSlot];
            setFormFields({ ...formFields, 'timeSlots': sortTimeSlots(newTimeSlots) });
        }
        setEditTimeSlot({});
        setTimeSlotPopOverAnchorEl(null);
    }

    const cancelUpdateTimeSlot = () => {
        setTimeSlotPopOverAnchorEl(null);
        setEditTimeSlot({});
    }

    const cancelAddTimeSlot = () => {
        setAddTimeSlotPopOverAnchorEl(null);
        setAddTimeSlot({});
    }

    const addTimeSlotDowSelected = (daysOfWeek) => {
        addTimeSlot.dow = daysOfWeek;
        setEditTimeSlot(addTimeSlot);
    }

    const saveBirthdayPartyDialog = (e) => {
        e.preventDefault();

        if (birthdayPartyId) {
            saveTheBirthdayParty(status);
        } else {
            setShowSaveDialog(true);
        }

    }

    const saveAndActivateBirthdayParty = () => {
        saveTheBirthdayParty('active');
    }

    const saveBirthdayParty = () => {
        saveTheBirthdayParty(status);
    }

    const saveTheBirthdayParty = async (status) => {

        setShowSaveDialog(false);

        if (isDynamicSearch && length <= 0) {
            setAlertMessage({
                title: 'Dynamic Search Invalid',
                message: 'A dynamic search birthday party requires a length greater than 0'
            });
            return;
        }

        if (!isDynamicSearch && !useTimeSlots) {
            setAlertMessage({
                title: 'Reservation Method Needed',
                message: 'You must select either Dynamic Search or Time Slots'
            });
            return;
        }

        if (useTimeSlots && (!timeSlots || timeSlots.length <= 0)) {
            setAlertMessage({
                title: 'Time Slot Needed',
                message: 'You must provide at least 1 time slot or use Dynamic Search'
            });
            return;
        }

        if (parseFloat(price) <= 0.00) {
            setAlertMessage({
                title: 'Invalid Price',
                message: 'Price must be greater than zero.'
            });
            return;
        }

        if (notificationEmails && notificationEmails.length > 0) {
            for (const notifyEmail of notificationEmails) {
                if (!validator.isEmail(notifyEmail.email)) {
                    setAlertMessage({
                        title: 'Invalid Notification Email',
                        message: `${notifyEmail} is not a valid email address`
                    });
                    return;
                }
            }
        }

        if (!areaGroupings || areaGroupings.length <= 0) {
            setAlertMessage({
                title: 'Area Group(s) Required',
                message: 'At least 1 area group is required.'
            });
            return;
        }

        for (const aGroup of areaGroupings) {
            if (!aGroup.selectedAreaIds || aGroup.selectedAreaIds.length <= 0) {
                setAlertMessage({
                    title: 'Area Group Invalid',
                    message: 'At least 1 area per group is required.'
                });
                return;
            }
        }

        if (hasWhitespace(searchTerm)) {
            setAlertMessage({
                title: 'Invalid Party URL',
                message: "The Party Url Text cannot contain spaces. "
            });
            return;
        }

        if (hasSpecialChars(searchTerm)) {
            setAlertMessage({
                title: 'Invalid Party URL',
                message: "The Party Url Text cannot contain special characters. "
            });
            return;
        }

        setIsLoading(true);

        const filteredEmailAttachemnts = emailFileAttachments.filter(file => file.source === 'user');
        const response = await CreateOrUpdateBirthdayParty(formFields, userData.facilityId, filteredEmailAttachemnts, deletedEmailFileAttachments, status);
        if (response.status === 200) {
            if (!response.data.status || response.data.status === 'success') {
                setAlertMessage(null);
                setSuccessMessage({
                    title: 'Saved',
                    message: 'The birthday party has been saved.'
                }
                );
            } else {
                if (response.data.errorMessage) {
                    setAlertMessage({
                        title: 'Save Error',
                        message: response.data.errorMessage
                    });
                } else {
                    setAlertMessage({
                        title: 'Save Error',
                        message: "There was an issue saving this birthday party.  The birthday party could not be saved."
                    });
                }
            }
        } else {
            setAlertMessage({
                title: 'Save Error',
                message: "There was an issue saving this birthday party.  The birthday party could not be saved."
            });
        }
        setIsLoading(false);
    }

    const closeAlertMessage = () => {
        setAlertMessage(null)
    }

    const closeSetup = () => {
        if (close) {
            close()
        } else {
            navigate('/facility/birthday-parties/manage');
        }
    }

    const birthdayPartySaveSuccessful = () => {
        if (close) {
            close()
        } else {
            navigate('/facility/birthday-parties/manage');
        }
    }

    const selectExistingGroup = (groupNameSelected) => {
        const foundGroup = birthdayPartyGroups.filter(g => g.groupName === groupNameSelected);
        if (foundGroup.length > 0) {
            setFormFields({ ...formFields, 'groupName': groupNameSelected, 'groupId': foundGroup[0].id });
        }
    }

    const closePopover = () => {
        setShowSecondaryColorPicker(false);
        setShowMainColorPicker(false);
        setPopOverAnchorEl(null);
    }

    const closeTimeSlotPopover = () => {
        setTimeSlotPopOverAnchorEl(null);
    }

    const closeAddTimeSlotPopover = () => {
        setAddTimeSlotPopOverAnchorEl(null);
    }

    const handleMainColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your main color.")
            return;
        }

        setFormFields({ ...formFields, ["mainTextColor"]: color.hex });
    };

    const handleSecondaryColorSelected = (color) => {

        if (color.hex === '#ffffff') {
            setAlertMessage("White cannot be your secondary color.")
            return;
        }

        setFormFields({ ...formFields, ["secondaryTextColor"]: color.hex });
    };

    const openMainColorPicker = (event) => {
        if (isEditMode) {
            setPopOverAnchorEl(event.currentTarget);
            setShowSecondaryColorPicker(false);
            setShowMainColorPicker(!showMainColorPicker);
        } else {
            setAlertMessage({
                title: 'Turn on Edit Mode',
                message: "You must be in edit mode to change."
            });
        }
    }

    const openSecondaryColorPicker = (event) => {
        if (isEditMode) {
            setPopOverAnchorEl(event.currentTarget);
            setShowMainColorPicker(false);
            setShowSecondaryColorPicker(!showSecondaryColorPicker);
        } else {
            setAlertMessage({
                title: 'Turn on Edit Mode',
                message: "You must be in edit mode to change."
            });
        }
    }

    const updateDynamicSearch = (event) => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'isDynamicSearch': !isDynamicSearch, 'useTimeSlots': !isDynamicSearch ? false : true, 'timeBetween': 0, length: '0', daysOfWeek: [], 'timeSlots': [] });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const updateUseTimeSlots = (event) => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'useTimeSlots': !useTimeSlots, 'isDynamicSearch': !useTimeSlots ? false : true, 'timeSlots': [] });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const timeSlotPopoverIsOpen = Boolean(timeSlotPopoverAnchorEl);
    const addTimeSlotPopoverIsOpen = Boolean(addTimeSlotPopoverAnchorEl);
    return (
        <Fragment>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                !isLoading &&
                <Box sx={{ marginTop: '1%', marginBottom: '3.0vh', overflow: 'scroll' }} display="flex" justifyContent="center" >
                    <button
                        ref={hiddenInputRef}
                        style={{ visibility: "hidden" }}>
                        Test
                    </button>
                    <Fab
                        variant="extended" color="primary"
                        sx={{
                            position: 'fixed',
                            top: '10%',
                            right: !isModal ? '0' : '2%',
                            marginTop: '0.25vh'
                        }}
                    >
                        <Box sx={{ width: '50%' }} display="flex" justifyContent="center" alignItems="center">
                            {isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button size="small" startIcon={<SaveOutlinedIcon />} id={'create-button'} variant="contained" type="submit" onClick={saveBirthdayParty}>Save</Button>
                                    {
                                        birthdayPartyId &&
                                        <Button size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelEdit}>Cancel</Button>
                                    }
                                </Stack>
                            }
                            {
                                !isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} variant="contained" onClick={edit}>Edit</Button>
                                </Stack>
                            }
                        </Box>
                    </Fab>
                    <Stack sx={{ width: '85%', marginLeft: '1.0vw', marginRight: '1.0vw' }} direction={'column'} justifyContent="flex-start">
                        {
                            ((birthdayPartyId && !isEditMode) || !birthdayPartyId) && close &&
                            <Box mt={2} display="flex" justifyContent="flex-start" alignItems="center">
                                <Button onClick={closeSetup} startIcon={<CloseOutlinedIcon />}>
                                </Button>
                            </Box>
                        }
                        <form autoComplete="off" onSubmit={saveBirthdayPartyDialog}>
                            <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
                                <Box mt={1} mb={2} display="flex" justifyContent="flex-end" alignItems="center">
                                    <Typography sx={{ width: '100%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1}>
                                        Birthday Party Setup
                                    </Typography>
                                </Box>
                                <Stack spacing={2} alignItems={'stretch'}>
                                    <Stack alignItems={'stretch'} direction={'row'} spacing={1}>
                                        <TextField InputProps={{ readOnly: !isEditMode }} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="name" variant="outlined" label="Name" type="text" name="name" value={name} onChange={handleChange} ></TextField>
                                        <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw', minWidth: '100px', maxWidth: '100px' }} id="price" variant="outlined" label="Price" type="text" name="price" value={price} onChange={handleChange}
                                            InputProps={{
                                                inputComponent: DeciamlFormat,
                                                readOnly: !isEditMode
                                            }}></TextField>
                                        <TextField sx={{ minWidth: '125px', maxWidth: '125px' }} fullWidth id="initial-deposit" variant="outlined" label="Initial Deposit" type="text" name="initialDeposit" value={initialDeposit} onChange={handleChange}
                                            InputProps={{
                                                inputComponent: DeciamlFormat,
                                                readOnly: !isEditMode
                                            }}></TextField>
                                        <FormControlLabel sx={{ color: '#14254C' }} control={<Checkbox disabled={!isEditMode} checked={refundableDeposit} onChange={handleRefundableDeposit} />} label="Refundable" />
                                        <TextField required sx={{ ml: '1.0vw', mr: '1.0vw', minWidth: '100px', maxWidth: '110px' }} id="capicity" variant="outlined" label="Min Capacity" type="number" name="minCapicity" value={minCapicity} onChange={handleChange}
                                            InputProps={{
                                                inputComponent: NoDeciamlFormat,
                                                readOnly: !isEditMode
                                            }} ></TextField>
                                        <TextField required sx={{ ml: '1.0vw', mr: '1.0vw', minWidth: '100px', maxWidth: '110px' }} id="capicity" variant="outlined" label="Max Capacity" type="number" name="maxCapicity" value={maxCapicity} onChange={handleChange}
                                            InputProps={{
                                                inputComponent: NoDeciamlFormat,
                                                readOnly: !isEditMode
                                            }}></TextField>
                                    </Stack>
                                    {
                                        birthdayPartyGroupNames.length > 0 &&
                                        <SelectBirthdayPartyGroup groups={birthdayPartyGroupNames} groupSelected={groupName ? groupName : ''} selectGroup={selectExistingGroup}></SelectBirthdayPartyGroup>
                                    }
                                    <Stack ml={2} mt={2} spacing={4} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                        {
                                            userData &&
                                            <CustomDisableInput helperText={`Customer link: www.mysportspace.com/birthday-parties/${userData.searchTerm ? userData.searchTerm : userData.facilityId}/${searchTerm ? searchTerm : id}`} size="small" disabled={!isEditMode} sx={{ mt: '1.0vh', mb: '1.0vh' }} id="searchTerm" variant="outlined" label="Party Web Url" type="text" name="searchTerm" value={searchTerm ? searchTerm : ""} onChange={handleChange} ></CustomDisableInput>
                                        }
                                        <Stack spacing={4} direction={'row'}>
                                            <Typography sx={{ fontSize: '2.0vh' }} variant="subtitle1" color={"#14254C"}>Main Text Color</Typography>
                                            <Box sx={{
                                                height: '25px',
                                                width: '25px',
                                                backgroundColor: `${mainTextColor}`,
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                cursor: 'pointer'
                                            }} onClick={openMainColorPicker}>
                                            </Box>

                                            <Popover
                                                id={'main-text-color-popover'}
                                                open={showMainColorPicker}
                                                anchorEl={popoverAnchorEl}
                                                onClose={closePopover}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <SketchPicker color={mainTextColor} onChangeComplete={handleMainColorSelected} ></SketchPicker>
                                            </Popover>
                                        </Stack>
                                        <Stack spacing={4} direction={'row'}>
                                            <Typography sx={{ fontSize: '2.0vh' }} variant="subtitle1" color={"#14254C"}>Secondary Text Color</Typography>
                                            <Box sx={{
                                                height: '25px',
                                                width: '25px',
                                                backgroundColor: `${secondaryTextColor}`,
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                cursor: 'pointer'
                                            }} onClick={openSecondaryColorPicker} >
                                            </Box>
                                            <Popover
                                                id={'main-text-color-popover'}
                                                open={showSecondaryColorPicker}
                                                anchorEl={popoverAnchorEl}
                                                onClose={closePopover}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <SketchPicker color={secondaryTextColor} onChangeComplete={handleSecondaryColorSelected}></SketchPicker>
                                            </Popover>
                                        </Stack>
                                    </Stack>
                                    <Divider></Divider>
                                    <FormLabel sx={{ mb: 1, fontWeight: 'bold', color: "#14254C" }}>Reservation Method</FormLabel>
                                    <Stack justifyContent={'space-between'} spacing={1} direction={'row'}>
                                        <FormControlLabel sx={{ color: "#14254C", fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="dynamicSearch" checked={isDynamicSearch} onChange={updateDynamicSearch} />} label="Dynamic Search" />
                                        {
                                            isDynamicSearch &&
                                            <Fragment>
                                                <CustomDisableInput disabled={!isEditMode || (isEditMode && !isDynamicSearch)} required sx={{ minWidth: '125px', maxWidth: '125px' }} fullWidth id="birthday-setup-length" variant="outlined" label="Length (Hours)" type="number" name="length" value={length} onChange={handleChange}
                                                    inputProps={{
                                                        step: "0.25",
                                                        max: "24",
                                                        min: "1",
                                                        pattern: "^\d*\.\d{2}$"
                                                    }}></CustomDisableInput>
                                                <CustomDisableInput disabled={!isEditMode || (isEditMode && !isDynamicSearch)} sx={{ minWidth: '125px', maxWidth: '120px' }} fullWidth id="timeBetween" variant="outlined" label="Time Between" type="number" name="timeBetween" value={timeBetween} onChange={handleChange}
                                                    InputProps={{
                                                        step: "15"
                                                    }}></CustomDisableInput>
                                                <CustomDisableInput disabled={!isEditMode || (isEditMode && !isDynamicSearch)} InputProps={{ readOnly: !isEditMode }} fullWidth id="birthday-setup-dows" variant="outlined" label="Days Of Week" type="text" onClick={handleDayOfWeekClick} name="repeat-dows" value={daysOfWeek && daysOfWeek.length > 0 ? getDaysOfWeekText(daysOfWeek) : "All"} ></CustomDisableInput>
                                                {
                                                    showDowList &&
                                                    <SearchDaysOfWeekList isPopOver={false} selectedDayOfWeek={daysOfWeek} dayOfWeekSelected={dayOfWeekSelected}></SearchDaysOfWeekList>
                                                }
                                            </Fragment>
                                        }
                                    </Stack>
                                    <Divider>OR</Divider>
                                    <Stack justifyContent={'flex-start'} spacing={1} direction={'row'}>
                                        <FormControlLabel sx={{ color: "#14254C", fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="useTimeSlots" checked={useTimeSlots} onChange={updateUseTimeSlots} />} label="Time Slots" />
                                        {
                                            isEditMode && useTimeSlots &&
                                            <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddTimeSlot}></Button>
                                        }
                                        <Popover
                                            id={'update-time-slot-popover'}
                                            open={timeSlotPopoverIsOpen}
                                            anchorEl={timeSlotPopoverAnchorEl}
                                            onClose={closeTimeSlotPopover}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Box>
                                                <Typography mt={2} ml={2} mb={2} color={'primary'} variant="h6" fontWeight='bold' >Edit Time Slot</Typography>
                                                <Stack ml={3} mb={2} mr={2} key={'add-time-slot-popover'} spacing={1} direction={'row'}>
                                                    <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} id={editTimeSlot.id} type={'start_time'} value={editTimeSlot.startTime} onChange={handleTimeSlotChange} />
                                                    <MaterialUITimePicker headerText="End Time:" views={['hours', 'minutes']} format={'h:mm A'} id={editTimeSlot.id} type={'end_time'} value={editTimeSlot.endTime} onChange={handleTimeSlotChange} />
                                                </Stack>
                                            </Box>
                                            <Stack sx={{ width: '100%' }} mb={2} mt={2} spacing={2} direction={'row'} display={"flex"} justifyContent={'center'}>
                                                <Button onClick={cancelUpdateTimeSlot}>Cancel</Button>
                                                <Button variant="contained" onClick={updateTimeSlot}>Update</Button>
                                            </Stack>
                                        </Popover>
                                        <Popover
                                            id={'add-time-slot-popover'}
                                            open={addTimeSlotPopoverIsOpen}
                                            anchorEl={addTimeSlotPopoverAnchorEl}
                                            onClose={closeAddTimeSlotPopover}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Box>
                                                <Typography mt={2} ml={2} mb={2} color={'primary'} variant="h6" fontWeight='bold' >Add Time Slots</Typography>
                                                <Stack ml={3} mb={2} mr={2} key={'add-time-slot-popover'} spacing={1} direction={'row'}>
                                                    <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} id={addTimeSlot.id} type={'start_time'} value={addTimeSlot.startTime} onChange={handleAddTimeSlotChange} />
                                                    <MaterialUITimePicker headerText="End Time:" views={['hours', 'minutes']} format={'h:mm A'} id={addTimeSlot.id} type={'end_time'} value={addTimeSlot.endTime} onChange={handleAddTimeSlotChange} />
                                                </Stack>
                                                <Box ml={2} mr={2}>
                                                    <SearchDaysOfWeekList showButtons={false} selectedDayOfWeek={addTimeSlot.dow} dayOfWeekSelected={addTimeSlotDowSelected}></SearchDaysOfWeekList>
                                                </Box>
                                            </Box>
                                            <Stack sx={{ width: '100%' }} mb={2} mt={2} spacing={2} direction={'row'} display={"flex"} justifyContent={'center'}>
                                                <Button onClick={cancelAddTimeSlot}>Cancel</Button>
                                                <Button variant="contained" onClick={addTimeSlots}>Add</Button>
                                            </Stack>
                                        </Popover>
                                    </Stack>
                                    {
                                        timeSlots && timeSlots.length > 0 &&
                                        <Stack spacing={1}>
                                            {
                                                SEARCH_DAYS_OF_WEEK_LONG.map((dow) => {

                                                    return <Stack spacing={1} key={`dow-stack-${dow}`}>
                                                        {
                                                            timeSlots.filter(ts => ts.dow === dow).length > 0 &&
                                                            <Typography fontWeight={'bold'} color={'primary'} ml={3}>{dow}</Typography>
                                                        }
                                                        {
                                                            timeSlots.filter(ts => ts.dow === dow).map((timeSlot) => {
                                                                return <Box key={`dow-box-${dow}`}>
                                                                    <Stack mt={2} ml={5} direction={'row'} spacing={1}>
                                                                        <CustomDisableInput disabled size="small" label={'Start Time'} value={timeSlot.startTime.format('h:mm a')}></CustomDisableInput>
                                                                        <CustomDisableInput disabled size="small" label={'End Time'} value={timeSlot.endTime.format('h:mm a')}></CustomDisableInput>
                                                                        {
                                                                            isEditMode &&
                                                                            <Fragment>
                                                                                <IconButton size="small" edge="end" aria-label="delete" onClick={handleEditTimeSlot.bind(this)} data-id={timeSlot.id}>
                                                                                    <EditOutlinedIcon />
                                                                                </IconButton>
                                                                                <IconButton edge="end" aria-label="delete" onClick={deleteSelectedTimeSlot.bind(this)} data-id={timeSlot.id} id={dow}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Fragment>
                                                                        }
                                                                    </Stack>
                                                                </Box>
                                                            })
                                                        }
                                                    </Stack>
                                                }
                                                )
                                            }
                                        </Stack>
                                    }
                                    <Divider></Divider>
                                    <Stack>
                                        {
                                            isEditMode &&
                                            <Fragment>
                                                <FormLabel sx={{ mb: 1, fontWeight: 'bold', color: "#14254C" }}>Sub Description</FormLabel>
                                                <Textarea required minRows={5} id="description" placeholder="A brief description of the birthday party" type="text" name="description" value={description} onChange={handleChange} ></Textarea>
                                            </Fragment>
                                        }
                                        {
                                            !isEditMode &&
                                            <TextField multiline InputProps={{ readOnly: true }} fullWidth sx={{ mr: '1.0vw' }} id="name" variant="outlined" label="Sub Description" type="text" name="description" value={description}></TextField>
                                        }
                                        <Box sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="left" alignItems="center" >
                                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Birthday Party Description Details</FormLabel>
                                            {
                                                isEditMode &&
                                                <Stack direction={'row'}>
                                                    <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddDetailsClick}></Button>
                                                </Stack>
                                            }
                                        </Box>
                                        <Box sx={{ marginBottom: '1.0vh' }}>
                                            {
                                                (!descriptions || descriptions.length <= 0) &&
                                                <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Descriptions</FormLabel>
                                            }
                                            {
                                                descriptions && descriptions.length > 0 &&
                                                <Stack spacing={1}> {
                                                    descriptions.sort((d1, d2) => {
                                                        if (d1.sortOrder < d2.sortOrder) {
                                                            return -1;
                                                        }

                                                        if (d1.sortOrder > d2.sortOrder) {
                                                            return 1;
                                                        }

                                                        return 0;
                                                    }).map((desc) => {
                                                        const { id, title, details, sortOrder } = desc;
                                                        return <Stack key={id} spacing={1} direction={'row'}>
                                                            {
                                                                <Fragment>
                                                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                                                        <TextField InputProps={{ readOnly: !isEditMode }} required fullWidth id={id} variant="outlined" label="Title" type="text" name="title" value={title} onChange={handleDescriptionChange} ></TextField>
                                                                        {isEditMode &&
                                                                            <Textarea required minRows={5} id={id} placeholder="Details to display for this title" type="text" name="details" value={details} onChange={handleDescriptionChange} ></Textarea>
                                                                        }
                                                                        {!isEditMode &&
                                                                            <TextField InputProps={{ readOnly: true }} multiline required fullWidth id={id} variant="outlined" label="Details" type="text" name="details" value={details} ></TextField>
                                                                        }
                                                                    </Stack>
                                                                    {
                                                                        isEditMode &&
                                                                        <Box mt={1} mb={2} display={'flex'}>
                                                                            {
                                                                                sortOrder !== 1 &&
                                                                                <IconButton edge="end" aria-label="moveup" onClick={moveUpDescription.bind(this)} data-id={id}>
                                                                                    <ArrowUpwardIcon />
                                                                                </IconButton>
                                                                            }

                                                                            {
                                                                                sortOrder !== descriptions.length &&
                                                                                <IconButton edge="end" aria-label="movedown" onClick={moveDownDescription.bind(this)} data-id={id}>
                                                                                    <ArrowDownwardIcon />
                                                                                </IconButton>
                                                                            }

                                                                            <IconButton edge="end" aria-label="delete" onClick={deleteSelectedDescription.bind(this)} data-id={id}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Box>

                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Stack>
                                                    })
                                                }
                                                </Stack>
                                            }
                                        </Box>
                                    </Stack>

                                    <Divider></Divider>
                                    <BirthdayPartyAreaGroupings formFields={formFields} updateFormFields={updateFormFields} isEditMode={isEditMode}></BirthdayPartyAreaGroupings>
                                    <Divider></Divider>
                                    <Stack>
                                        <Box sx={{ marginBottom: '1.0vh' }} display="flex" justifyContent="left" alignItems="center" >
                                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Ancillaries</FormLabel>
                                            {
                                                isEditMode &&
                                                <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddAncillariyClick}></Button>
                                            }
                                        </Box>
                                        <Box sx={{ marginBottom: '1.0vh' }}>
                                            {
                                                (!ancillaries || ancillaries.length <= 0) &&
                                                <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Ancillaries</FormLabel>
                                            }
                                            {
                                                ancillaries && ancillaries.length > 0 &&
                                                <Stack spacing={1}> {
                                                    ancillaries.map((ancillary) => {
                                                        const { id, name, description, taxable, price } = ancillary;
                                                        return <Stack key={id} spacing={1} direction={'row'}>
                                                            {
                                                                <Fragment>
                                                                    <TextField InputProps={{ readOnly: !isEditMode }} multiline required fullWidth id={id} variant="outlined" label="Name" type="text" name="name" value={name} onChange={handleAncillaryChange} ></TextField>
                                                                    <TextField InputProps={{ readOnly: !isEditMode }} multiline required fullWidth id={id} variant="outlined" label="Description" type="text" name="description" value={description} onChange={handleAncillaryChange} ></TextField>
                                                                    <TextField sx={{ minWidth: '125px', maxWidth: '125px' }} id={id} variant="outlined" label="Price" type="text" name="price" value={price} onChange={handleAncillaryChange}
                                                                        InputProps={{
                                                                            inputComponent: DeciamlFormat,
                                                                            readOnly: !isEditMode
                                                                        }}>
                                                                    </TextField>
                                                                    <FormControlLabel control={<Checkbox id={id} disabled={!isEditMode} name="taxable" checked={taxable} onChange={handleAncillaryTaxableChange} />} label="Taxable" />
                                                                    {
                                                                        isEditMode &&
                                                                        <IconButton edge="end" aria-label="delete" onClick={deleteSelectedAncillary.bind(this)} data-id={id}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Stack>
                                                    })
                                                }
                                                </Stack>
                                            }
                                        </Box>
                                    </Stack>
                                    <Divider></Divider>
                                    <Stack>
                                        <Box sx={{ marginBottom: '1.0vh' }} display="flex" justifyContent="left" alignItems="center" >
                                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Notification Emails</FormLabel>
                                            {
                                                isEditMode &&
                                                <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddNotificationEmailClick}></Button>
                                            }
                                        </Box>
                                        <Box sx={{ marginBottom: '1.0vh' }}>
                                            {
                                                !notificationEmails || notificationEmails.length <= 0 &&
                                                <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Notification Emails</FormLabel>
                                            }
                                            {
                                                notificationEmails && notificationEmails.length > 0 &&
                                                <Stack spacing={1}> {
                                                    notificationEmails.map((notificationEmail) => {
                                                        const { id, email } = notificationEmail;
                                                        return <Stack key={id} spacing={1} direction={'row'}>
                                                            {
                                                                <Fragment>
                                                                    <TextField InputProps={{ readOnly: !isEditMode }} required fullWidth id={id} variant="outlined" label="Email Address" type="text" name="email" value={email} onChange={handleNotificationEmailChange} ></TextField>
                                                                    {
                                                                        isEditMode &&
                                                                        <IconButton edge="end" aria-label="delete" onClick={deleteNotificationEmail.bind(this)} data-id={id}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Stack>
                                                    })
                                                }
                                                </Stack>
                                            }
                                        </Box>
                                    </Stack>
                                    <Divider></Divider>
                                    <Stack>
                                        <Box sx={{ marginBottom: '1.0vh' }} display="flex" justifyContent="left" alignItems="center" >
                                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Custom Fields</FormLabel>
                                            {
                                                isEditMode &&
                                                <Button startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddCustomFieldClick}></Button>
                                            }
                                        </Box>
                                        <Box sx={{ marginBottom: '1.0vh' }}>
                                            {
                                                !customFields || customFields.length <= 0 &&
                                                <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Custom Fields</FormLabel>
                                            }
                                            {
                                                customFields && customFields.length > 0 &&
                                                <Stack spacing={1}> {
                                                    customFields.map((field) => {
                                                        const { id, text } = field;
                                                        return <Stack key={id} spacing={1} direction={'row'}>
                                                            {
                                                                <Fragment>
                                                                    <TextField InputProps={{ readOnly: !isEditMode }} required fullWidth id={id} variant="outlined" label="Field Text" type="text" name="text" value={text} onChange={handleCustomFieldChange} ></TextField>
                                                                    {
                                                                        isEditMode &&
                                                                        <IconButton edge="end" aria-label="delete" onClick={deleteCustomField.bind(this)} data-id={id}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    }

                                                                </Fragment>
                                                            }
                                                        </Stack>
                                                    })
                                                }
                                                </Stack>
                                            }
                                        </Box>
                                    </Stack>
                                    <Divider></Divider>
                                    <Stack>
                                        <Box sx={{ marginBottom: '1.0vh' }} display="flex" justifyContent="left" alignItems="center" >
                                            <FormLabel sx={{ mr: 1, fontWeight: 'bold', color: "#14254C" }}>Email File Attachments</FormLabel>
                                            {
                                                isEditMode &&
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    tabIndex={-1}
                                                    startIcon={<AddCircleOutlineOutlinedIcon />}
                                                >
                                                    <VisuallyHiddenInput onChange={handleAddEmailFileClick} type="file" />
                                                </Button>
                                            }
                                        </Box>
                                        <Box sx={{ marginBottom: '1.0vh' }}>
                                            {
                                                !emailFileAttachments || emailFileAttachments.length <= 0 &&
                                                <FormLabel sx={{ ml: 1, mr: 1, color: "#14254C" }}>No Email File Attachments</FormLabel>
                                            }
                                            {
                                                emailFileAttachments && emailFileAttachments.length > 0 &&
                                                <Stack spacing={1}> {
                                                    emailFileAttachments.map((field) => {
                                                        const { name, id, source } = field;
                                                        return <Stack key={`file-stack-${id}`} spacing={1} direction={'row'}>
                                                            {
                                                                <Fragment>
                                                                    <TextField key={`file-field-${id}`} InputProps={{ readOnly: true }} fullWidth id={id} variant="outlined" label={'File Name'} type="text" name="text" value={name}></TextField>
                                                                    {
                                                                        source === 'cloud_storage' &&
                                                                        <IconButton key={`file-download-btn-${id}`} edge="end" aria-label="delete" onClick={downloadEmailFileAttachment.bind(this)} data-id={id}>
                                                                            <DownloadOutlinedIcon key={`file-download-icon-${id}`} />
                                                                        </IconButton>
                                                                    }
                                                                    {
                                                                        isEditMode &&
                                                                        <IconButton key={`file-del-btn-${id}`} edge="end" aria-label="delete" onClick={deleteEmailFileAttachment.bind(this)} data-id={id}>
                                                                            <DeleteIcon key={`file-del-icon-${id}`} />
                                                                        </IconButton>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Stack>
                                                    })
                                                }
                                                </Stack>
                                            }
                                        </Box>
                                        <Divider></Divider>
                                        {
                                            !isEditMode &&
                                            <TextField multiline InputProps={{ readOnly: true }} fullWidth sx={{ mt: '2.0vh' }} id="emailInstructions" variant="outlined" label="Email Instructions" type="text" name="description" value={emailInstructions}></TextField>
                                        }
                                        {
                                            isEditMode &&
                                            <Box sx={{ marginBottom: '1.0vh' }}>
                                                <Stack>
                                                    <FormLabel sx={{ mt: 1, fontWeight: 'bold', color: "#14254C" }}>Email Instructions</FormLabel>
                                                    <Textarea minRows={5} id="emailinstructions" placeholder="Email Instructions" type="text" name="emailInstructions" value={emailInstructions} onChange={handleChange} ></Textarea>
                                                </Stack>
                                            </Box>
                                        }
                                    </Stack>
                                </Stack>
                            </FormControl >
                        </form>
                    </Stack>
                    <Dialog
                        open={showSaveDialog}
                        onClose={handleSaveDialogClose}
                    >
                        <DialogTitle>Save Birthday Party?</DialogTitle>
                        <DialogContent>
                            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle2" component="div">
                                Activate
                            </Typography>
                            <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body2" component="div" ml={2}>
                                The birthday party will be saved and available to customers immediately.
                            </DialogContentText>
                            <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle2" component="div">
                                Save
                            </Typography>
                            <Typography gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body2" component="div" ml={2}>
                                The birthday party will be saved but you will need to activate later for customers to book.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSaveDialogClose}>Cancel</Button>
                            <Button onClick={saveAndActivateBirthdayParty} variant="contained">Activate</Button>
                            <Button onClick={saveBirthdayParty} variant="contained">Save</Button>
                        </DialogActions>
                    </Dialog>
                    <MySportSpaceAlert isOpen={leaveMessage ? true : false} title={'Leave Page?'} message={leaveMessage} okButtonText={'Yes'} okAction={leavePage} cancelButtonText={'No'} cancelAction={cancelLeavePage}></MySportSpaceAlert>
                    <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
                    <MySportSpaceAlert isOpen={successMessage ? true : false} title={successMessage ? successMessage.title : ''} message={successMessage ? successMessage.message : ''} okButtonText={'OK'} okAction={birthdayPartySaveSuccessful}></MySportSpaceAlert>
                </Box >
            }
        </Fragment >
    )

}

export default BirthdayPartySetup;