import { Box, Modal, Stack, Typography, IconButton, FormControlLabel, Switch, Button, Divider } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component";
import { useState, useContext, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import MySportSpaceAlert from "../../alert/alert.component";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { UserContext } from "../../../contexts/user.context";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component";
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import FacilityAreaListAll from "../facility-area-list-all/facility-area-list-all.component";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { findAreaForId } from "../../../utils/reservation-utils/reservation-utils";
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { saveReservationTimeAdjustment } from "../../../utils/firebase/facility-firebase-utils";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll',
    width: '90%',
    height: '90%'
};

const ReservationTimeAdjustmentSetup = ({ isOpen = false, isAdding = false, timeAdjustmentIn, closeView }) => {

    const [timeAdjustment, setTimeAdjustment] = useState(timeAdjustmentIn);
    const [isEditMode, setIsEditMode] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const { isAdmin, userData } = useContext(UserContext);
    const [editLockName] = useState(timeAdjustmentIn ? `Res-Time-Adjustment-${timeAdjustmentIn.id}` : 'Res-Time-Adjustment');
    const [isLoading, setIsLoading] = useState(false);
    const [editModeAlert, setEditModeAlert] = useState(null);
    const [leaveMessage, setLeaveMessage] = useState(null);
    const [savedMessage, setSavedMessage ] = useState(null);
    const { facilityAreas } = useContext(FacilityAreaContext);
    const [facilityAreaPopOverAnchorEl, setFacilityAreaPopOverAnchorEl] = useState(null);

    const { startDate, endDate, startTime, endTime, mustRentOnTopOfHour, hourlyIncrementsOnly, mandatoryLength, minimumReservationLength, facilityAreaIds = [] } = timeAdjustment ? timeAdjustment : {};

    useEffect(() => {

        const setupEditMode = (edit) => {
            setIsEditMode(edit);
        }

        setupEditMode(isAdding);
    }, [isAdding, timeAdjustmentIn]);

    const save = async () => {

        if (startDate && endDate){
           if (dayjs(`${endDate} 8:00 AM`, 'YYYY-MM-DD h:mm A').isSameOrBefore(dayjs(`${startDate} 8:00 AM`, 'YYYY-MM-DD h:mm A'))) {
            setAlertMessage("Start Date must be less than End Date");
            return;
           }
        }

        if (startTime && endTime){
            if (dayjs(`1970-01-01 ${endTime}`, 'YYYY-MM-DD h:mm A').isSameOrBefore(dayjs(`1970-01-01 ${startTime}`, 'YYYY-MM-DD h:mm A'))) {
             setAlertMessage("Start Time must be less than End Time");
             return;
            }
         }

         setIsLoading(true);
         const saved = saveReservationTimeAdjustment(userData.facilityId, timeAdjustment);
         setIsLoading(false);

         if (saved){
            setSavedMessage("The reservation adjustment has been saved.");
         } else {
            setAlertMessage("An error occurred, the reservation time adjustment was not saved.");
         }

    }

    const selectStartDate = (value) => {
        setTimeAdjustment({ ...timeAdjustment, 'startDate': value.format("YYYY-MM-DD") });
    }

    const setHasStartDate = () => {
        if (isEditMode) {
            if (startDate) {
                setTimeAdjustment({ ...timeAdjustment, 'startDate': null });
            } else {
                const dateValue = dayjs().format("YYYY-MM-DD");
                setTimeAdjustment({ ...timeAdjustment, 'startDate': dateValue });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const selectEndDate = (value) => {
        setTimeAdjustment({ ...timeAdjustment, 'endDate': value.format("YYYY-MM-DD") });
    }

    const setHasEndDate = () => {
        if (isEditMode) {
            if (endDate) {
                setTimeAdjustment({ ...timeAdjustment, 'endDate': null });
            } else {
                const dateValue = dayjs().format("YYYY-MM-DD");
                setTimeAdjustment({ ...timeAdjustment, 'endDate': dateValue });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const selectStartTime = (value) => {
        setTimeAdjustment({ ...timeAdjustment, 'startTime': value.format("h:mm A") });
    }

    const setHasStartTime = () => {
        if (isEditMode) {
            if (startTime) {
                setTimeAdjustment({ ...timeAdjustment, 'startTime': null });
            } else {
                setTimeAdjustment({ ...timeAdjustment, 'startTime': "8:00 AM" });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const selectEndTime = (value) => {
        setTimeAdjustment({ ...timeAdjustment, 'endTime': value.format("h:mm A") });
    }

    const setHasEndTime = () => {
        if (isEditMode) {
            if (endTime) {
                setTimeAdjustment({ ...timeAdjustment, 'endTime': null });
            } else {
                setTimeAdjustment({ ...timeAdjustment, 'endTime': "8:00 AM" });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const close = () => {
        if (isEditMode) {
            cancelPrompt();
        } else {
            closeView();
        }
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation settings.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const cancelPrompt = () => {
        if (isAdding) {
            setEditModeAlert("Are you sure you want to cancel adding this time adjustment?");
        } else {
            setEditModeAlert("Are you sure you want to cancel editing?  Any unsaved changes will be lost.");
        }

    }

    const stayInEditMode = () => {
        setEditModeAlert(null);
    }

    const leaveEditMode = () => {

        setEditModeAlert(null);
        removeEditLock(editLockName, userData);

        if (isAdding) {
            if (closeView) {
                closeView();
            }
        } else {
            setIsEditMode(false);
        }
    }

    const leavePage = () => {
        removeEditLock(editLockName, userData);
        setIsEditMode(false);
        setLeaveMessage(null);
        setSavedMessage(null);
        if (closeView) {
            closeView();
        }
    };

    const cancelLeavePage = () => {
        setLeaveMessage(null);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTimeAdjustment({ ...timeAdjustment, [name]: parseInt(value) });
    }

    const setTopOfHourOnly = () => {
        if (isEditMode) {
            setTimeAdjustment({ ...timeAdjustment, 'mustRentOnTopOfHour': !mustRentOnTopOfHour });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setHourlyIncrements = () => {
        if (isEditMode) {
            setTimeAdjustment({ ...timeAdjustment, 'hourlyIncrementsOnly': !hourlyIncrementsOnly });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setHasMandatoryLength = () => {
        if (isEditMode) {
            if (mandatoryLength) {
                setTimeAdjustment({ ...timeAdjustment, 'mandatoryLength': null });
            } else {
                setTimeAdjustment({ ...timeAdjustment, 'mandatoryLength': 60 });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setHasMinimumLength = () => {
        if (isEditMode) {
            if (minimumReservationLength) {
                setTimeAdjustment({ ...timeAdjustment, 'minimumReservationLength': null });
            } else {
                setTimeAdjustment({ ...timeAdjustment, 'minimumReservationLength': 60 });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const deleteFacilityAreaId = (event) => {
        event.preventDefault();
        const areaId = event.currentTarget.dataset.id;
        selectFacilityAreaId(areaId);
    }

    const selectFacilityAreaId = (facilityAreaId) => {
        const existingFacilityArea = facilityAreaIds.find((id) => id === facilityAreaId);
        if (existingFacilityArea) {
            setTimeAdjustment({ ...timeAdjustment, 'facilityAreaIds': facilityAreaIds.filter((id) => id !== facilityAreaId) });
        } else {
            const newFacilityIds = [facilityAreaId].concat(facilityAreaIds)
            setTimeAdjustment({ ...timeAdjustment, 'facilityAreaIds': newFacilityIds });
        }
    }

    const handleAddAreaClick = (event) => {
        event.preventDefault();
        setFacilityAreaPopOverAnchorEl(facilityAreaPopOverAnchorEl ? null : event.currentTarget);
    };

    const handleCloseAddAreaClick = () => {
        setFacilityAreaPopOverAnchorEl(null);
    };

    return (<Modal open={isOpen}>
        <Box sx={{ ...style, }}>
            <MySportSpaceAlert isOpen={leaveMessage ? true : false} title={'Leave Page?'} message={leaveMessage} okButtonText={'Yes'} okAction={leavePage} cancelButtonText={'No'} cancelAction={cancelLeavePage}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={savedMessage ? true : false} message={savedMessage} okButtonText={'OK'} okAction={leavePage} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={editModeAlert ? true : false} title={`${!isAdding ? 'Cancel Edit?' : 'Cancel Add?'}`} message={editModeAlert} okButtonText={`${!isAdding ? 'Yes' : 'Yes'}`} okAction={leaveEditMode} cancelButtonText={"No"} cancelAction={stayInEditMode}></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <Stack sx={{ width: '95%' }} overflow={'scroll'} direction={'row'} mt={2} mb={2}>
                <Box sx={{ width: '50%' }}>
                    <Stack direction={'row'} display={'flex'} alignItems={'center'}>
                        <IconButton onClick={close}>
                            <CloseOutlinedIcon />
                        </IconButton>
                        <Typography ml={2} fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="h5"> {isAdding ? 'New Reservation Time Adjustment' : 'Reservation Time Adjustment'}  </Typography>
                    </Stack>
                </Box>
                <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                    {isEditMode &&
                        <Stack spacing={1} direction={'row'}>
                            <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<SaveOutlinedIcon />} id={'create-button'} variant="contained" type="submit" onClick={save}>Save</Button>
                            <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelPrompt}>Cancel</Button>
                        </Stack>
                    }
                    {
                        !isEditMode &&
                        <Stack spacing={1} direction={'row'}>
                            <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<EditOutlinedIcon />} id={'edit-res-time-adj-button'} variant="contained" onClick={edit}>Edit</Button>
                        </Stack>
                    }
                </Box>
            </Stack>
            <Typography mt={2} sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                Date Range
            </Typography>
            <Stack sx={{ width: '100%' }} mt={1} mb={2} spacing={2} direction={'row'} >
                <Stack sx={{ width: '35%' }} mb={1} ml={2} spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="startDate" checked={startDate ? true : false} onChange={setHasStartDate} />} label="Start Date" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            The date this becomes effective (Not Required)
                        </Typography>
                    </Stack>
                    {
                        startDate &&
                        <MaterialUIDatePicker disabled={!isEditMode} labelText="Start Date" minDate={dayjs().startOf('day')} onChange={selectStartDate} value={dayjs(startDate, 'YYYY-MM-DD')}></MaterialUIDatePicker>
                    }
                </Stack>
                <Stack sx={{ width: '35%' }} mb={2} spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="endDate" checked={endDate ? true : false} onChange={setHasEndDate} />} label="End Date" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            The date when this ends (Not Required)
                        </Typography>
                    </Stack>
                    {
                        endDate &&
                        <MaterialUIDatePicker disabled={!isEditMode} labelText="End Date" minDate={dayjs().startOf('day')} onChange={selectEndDate} value={dayjs(endDate, 'YYYY-MM-DD')}></MaterialUIDatePicker>
                    }
                </Stack>

            </Stack>
            <Divider></Divider>
            <Typography mt={2} sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                Time Range
            </Typography>
            <Stack sx={{ width: '100%' }} mt={1} spacing={2} direction={'row'}  >
                <Stack sx={{ width: '35%' }} mb={1} ml={2} spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="startTime" checked={startTime ? true : false} onChange={setHasStartTime} />} label="Start Time" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            The time of day this adjustent starts (Not Required)
                        </Typography>
                    </Stack>
                    {
                        startTime &&
                        <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} value={dayjs(`1970-01-01 ${startTime}`, 'YYYY-MM-DD h:mm A')} onChange={selectStartTime} />
                    }
                </Stack>
                <Stack sx={{ width: '35%' }} mb={1} spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="endTime" checked={endTime ? true : false} onChange={setHasEndTime} />} label="End Time" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            The time of day this adjustent ends (Not Required)
                        </Typography>
                    </Stack>
                    {
                        endTime &&
                        <MaterialUITimePicker headerText="End Time:" views={['hours', 'minutes']} format={'h:mm A'} value={dayjs(`1970-01-01 ${endTime}`, 'YYYY-MM-DD h:mm A')} onChange={selectEndTime} />
                    }
                </Stack>
            </Stack>
            <Divider></Divider>
            <Typography mt={2} sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                Time Adjustments
            </Typography>
            <Stack sx={{ width: '100%' }} mt={1} spacing={2} direction={'row'}  >
                <Stack>
                    <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="mustRentOnTopOfHour" checked={mustRentOnTopOfHour} onChange={setTopOfHourOnly} />} label="Top Of Hour Only" />
                    <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                        Reservations must start on the top of the hour (IE 5:00 pm not 5:30 pm)
                    </Typography>
                </Stack>
                <Stack>
                    <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="hourlyIncrementsOnly" checked={hourlyIncrementsOnly} onChange={setHourlyIncrements} />} label="Hourly Increments Only" />
                    <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                        Reservations can only be in hourly increments (IE 1 Hour, 2 Hours, not 2.5 Hours)
                    </Typography>
                </Stack>
                <Stack spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="mandatoryLength" checked={mandatoryLength} onChange={setHasMandatoryLength} />} label="Mandatory Length" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            Reservations must be this length
                        </Typography>
                    </Stack>
                    {
                        mandatoryLength &&
                        <CustomDisableInput InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="mandatoryLength" variant="outlined" label={`Minutes`} type="number" name="mandatoryLength" value={mandatoryLength} onChange={handleChange} ></CustomDisableInput>
                    }
                </Stack>
                <Stack spacing={2} direction={'row'}>
                    <Stack>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="minimumReservationLength" checked={minimumReservationLength} onChange={setHasMinimumLength} />} label="Minimum Length" />
                        <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                            Minimum Reservation Length
                        </Typography>
                    </Stack>
                    {
                        minimumReservationLength &&
                        <CustomDisableInput InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="minimumReservationLength" variant="outlined" label={`Minutes`} type="number" name="minimumReservationLength" value={minimumReservationLength} onChange={handleChange} ></CustomDisableInput>
                    }
                </Stack>
            </Stack>
            <Divider></Divider>
            <Box mt={2} mb={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <Typography sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                    Facility Areas
                </Typography>
                {isEditMode &&
                    <IconButton size='small' variant="contained" onClick={handleAddAreaClick}>
                        <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                }
            </Box>
            <FacilityAreaListAll marginTop={1} anchorEl={facilityAreaPopOverAnchorEl} selectedAreaIds={facilityAreaIds} selectFacilityAreaId={selectFacilityAreaId} closeList={handleCloseAddAreaClick} ></FacilityAreaListAll>
            {
                facilityAreaIds && facilityAreaIds.length > 0 &&
                <List sx={{ marginLeft: '1.0vw', borderRadius: '5px', borderWidth: '1px', borderColor: '#A9A9A9', borderStyle: 'solid' }} key={'res-time-adj-facility-area-list-key'}>
                    {
                        facilityAreaIds.map((areaId) => {
                            const area = findAreaForId(facilityAreas, areaId);
                            if (area) {
                                return <Fragment key={`${areaId}-list-frag`}>
                                    {
                                        <ListItem key={`${areaId}-list-del-item`}
                                            secondaryAction={
                                                isEditMode &&
                                                <IconButton edge="end" aria-label="delete" onClick={deleteFacilityAreaId.bind(this)} data-id={`${areaId}`}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }>

                                            <ListItemText key={`sel-${areaId}-list-item`} primary={area.name} primaryTypographyProps={{
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: '#14254C'
                                            }}></ListItemText>
                                        </ListItem>
                                    }
                                </Fragment>
                            }
                        })
                    }
                </List>
            }
        </Box>
    </Modal>
    )
}

export default ReservationTimeAdjustmentSetup;