import { createContext, useEffect, useContext, useReducer } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { UserContext } from "../user.context";
import { findSportsForAreas } from "../../utils/search-utils/search-utils";
import { createAction } from "../../utils/reducer/reducer.utils";
import { GetFacilityAreas } from "../../utils/facility-api/facility-service";

const findNumberOfAreas = (facilityAreas) => {
    let totalNumberOfAreas = 0;

    for (const area of facilityAreas) {
        totalNumberOfAreas += 1;
        if (area.subAreas) {
            let sAreaIds = Object.keys(area.subAreas);
            totalNumberOfAreas += sAreaIds.length;
        }
    }

    return totalNumberOfAreas;
}

export const FacilityAreaContext = createContext({
    facilityAreas: [],
    setFacilityAreas: () => [],
    allFacilityAreas: [],
    setAllFacilityAreas: () => [],
    sportsForAreas: [],
    setSportsForAreas: () => [],
    facilityIdOverride: null,
    setFacilityIdOverride: () => [],
    facilityAreasAreLoading: false,
    setFacilityAreasAreLoading: () => { }
});

export const FACILITY_AREA_ACTION_TYPES = {
    SET_FACILITY_AREAS: 'SET_FACILITY_AREAS',
    SET_LOADING_AREAS: "SET_LOADING_AREAS"
}

const INITIAL_STATE = {
    facilityAreas: [],
    allFacilityAreas: [],
    sportsForAreas: [],
    numberOfAreas: 0,
    facilityIdOverride: null,
    facilityAreasAreLoading: false
}

const facilityAreaReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case FACILITY_AREA_ACTION_TYPES.SET_FACILITY_AREAS:
            return {
                ...state, //this keeps everything else and doesn't overwrite
                ...payload
            }
        case FACILITY_AREA_ACTION_TYPES.SET_LOADING_AREAS:
            return {
                ...state,
                facilityAreasAreLoading: payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }

}

export const FacilityAreasProvider = ({ children }) => {

    const [{ facilityAreas, allFacilityAreas, sportsForAreas, numberOfAreas, facilityIdOverride, facilityAreasAreLoading }, dispatch] = useReducer(facilityAreaReducer, INITIAL_STATE);
    const { currentUser, userData } = useContext(UserContext);

    const setFacilityAreas = (facilityAreas) => {
        const sportsForFacilityAreas = findSportsForAreas(facilityAreas);
        const numAreas = findNumberOfAreas(facilityAreas);

        dispatch(createAction(FACILITY_AREA_ACTION_TYPES.SET_FACILITY_AREAS, { facilityAreas: facilityAreas, sportsForAreas: sportsForFacilityAreas, numberOfAreas: numAreas }));
    }

    const setAllFacilityAreas = (facilityAreas) => {
        dispatch(createAction(FACILITY_AREA_ACTION_TYPES.SET_FACILITY_AREAS, { allFacilityAreas: facilityAreas }));
    }

    const setFacilityAreasAreLoading = (bool) => {
        dispatch(createAction(FACILITY_AREA_ACTION_TYPES.SET_LOADING_AREAS, bool));
    }

    const setFacilityIdOverride = (facilityId) => {
        dispatch(createAction(FACILITY_AREA_ACTION_TYPES.SET_FACILITY_AREAS, { facilityIdOverride: facilityId }));
    }

    useEffect(() => {

        async function getAreas() {
            const response = await GetFacilityAreas(facilityIdOverride);
            if (response && response.status === 200 && response.data && response.data.status === 'success') {
                setFacilityAreas(response.data.facilityAreas);
            } else {
                setFacilityAreas([]);
            }
        }

        if (currentUser && userData && userData.isFacilityUserAccount) {
            const db = getDatabase();
            const areaRef = ref(db, `facility-user-accounts/${userData.facilityId}/facilityAreas`)
            const unsubscribe = onValue(areaRef, (snapshot) => {
                setFacilityAreasAreLoading(true);
                if (snapshot.exists()) {
                    const facilityAreaMap = snapshot.val();
                    let facilityAreas = [];
                    let allFacilityAreas = [];
                    Object.keys(facilityAreaMap).map((id) => {
                        const facilityArea = facilityAreaMap[id];
                        facilityArea.id = id;

                        if (facilityArea.subAreas) {
                            const subAreas = [];
                            Object.keys(facilityArea.subAreas).map((subAreaKey) => {
                                const subArea = facilityArea.subAreas[subAreaKey];
                                subArea.id = subAreaKey;
                                subArea.parentName = facilityArea.name;
                                subArea.isSubArea = true;
                                subAreas.push(subArea);
                                allFacilityAreas.push(subArea);
                                return;
                            });
                            subAreas.sort((sub1, sub2) => {
                                if (sub1.sortOrder < sub2.sortOrder) {
                                    return -1;
                                }

                                if (sub1.sortOrder > sub2.sortOrder) {
                                    return 1;
                                }

                                return 0;
                            })
                            facilityArea.subAreaList = subAreas;
                        }

                        facilityAreas.push(facilityArea);
                        allFacilityAreas.push(facilityArea);
                        return;
                    });

                    facilityAreas.sort((area1, area2) => {
                        if (area1.sortOrder < area2.sortOrder) {
                            return -1;
                        }

                        if (area1.sortOrder > area2.sortOrder) {
                            return 1;
                        }

                        return 0;
                    });

                    allFacilityAreas.sort((area1, area2) => {
                        if (area1.sortOrder < area2.sortOrder) {
                            return -1;
                        }

                        if (area1.sortOrder > area2.sortOrder) {
                            return 1;
                        }

                        return 0;
                    });

                    setFacilityAreas(facilityAreas);
                    setAllFacilityAreas(allFacilityAreas)
                } else {
                    setFacilityAreas([]);
                }
                setFacilityAreasAreLoading(false);
            })
            return unsubscribe;
        } else if (facilityIdOverride) {
            getAreas();
        }
    }, [currentUser, userData, facilityIdOverride]);

    const value = { facilityAreas, setFacilityAreas, allFacilityAreas, sportsForAreas, numberOfAreas, facilityIdOverride, setFacilityIdOverride, facilityAreasAreLoading, setFacilityAreasAreLoading }
    return <FacilityAreaContext.Provider value={value}>{children}</FacilityAreaContext.Provider>

}