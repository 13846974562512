import { BlackoutCreateContainer, RepeatsRow, DatesRow } from './blackout-create-styles.jsx';
import { useContext, useState, useEffect } from "react";
import { REPEATING_INTERVAL_TYPES, DAYS_OF_WEEK_LONG } from '../../../utils/constants/constants.js';
import { BlackoutCreateRow } from './blackout-create-styles.jsx';
import CalendarDatePicker from '../../calendar-date-picker/calendar-date-picker.component.jsx';
import CustomerList from '../../customer-list/customer-list.component.jsx';
import FacilityCustomersPicker from '../facility-customers-picker/facility-customers-picker.component';
import FacilityMembershipsPicker from '../facility-memberships-picker/facility-memberships-picker.component';
import MembershipList from '../../membership-list/membership-list.component.jsx';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component.jsx';
import MySportSpaceAlert from '../../alert/alert.component.jsx';
import { createBlackout } from '../../../utils/reservation-api/reservation-service.js';
import { UserContext } from '../../../contexts/user.context.jsx';
import { FacilityMembershipsContext } from '../../../contexts/facility/facility-memberships.context.jsx';
import ListWithTextInput from '../../list-with-text-input/list-with-text-input.component.jsx';
import { useNavigate } from "react-router-dom";
import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context.jsx';
import { TextField } from '@mui/material';
import dayjs from "dayjs";
import MaterialUIDatePicker from '../../material-ui/date-picker/material-ui-date-picker.component.jsx';
import MaterialUITimePicker from '../../material-ui/time-picker/material-ui-time-picker.component.jsx';
import MaterialUISelectionList from '../../material-ui/selection-list/material-ui-selection-list.component.jsx';
import { generateDayJSDatesForReservation } from '../../../utils/date-utilities/date-utilities.js';
import FacilityAreas from '../facility-areas/facility-areas.component.jsx';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BottomButtonBar from '../../bottom-button-bar/bottom-button-bar.component.jsx';
import { clearFacilityBlackoutReasonHistory, getFacilityBlackoutReasons } from '../../../utils/firebase/firebase.utils.js';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)


const defaultBlackoutInput = {
    title: null,
    selectedAreas: [],
    startDate: dayjs().startOf('day'),
    startTime: dayjs().startOf('hour'),
    endTime: dayjs().startOf('hour').add(1, 'hour'),
    repeatsInterval: null,
    repeatsUntilDate: null,
    repeatDaysOfWeek: [],
    repeatExceptionDates: [],
    notes: '',
    subTitle: '',
    reservedForCustomers: [],
    reservedForMemberships: []
}

const addCustomer = (customers, customerToAdd) => {
    //search for existing customer, using the find method.
    const existingCustomer = customers.find((customer) => customer.userId === customerToAdd.userId);
    if (existingCustomer) {
        return customers;
    }
    //this just creates a new arrray 
    let newCustomerArr = [...customers, customerToAdd];

    newCustomerArr.sort((customer1, customer2) => {
        if (customer1.userLastName < customer2.userLastName) {
            return -1;
        }

        if (customer1.userLastName > customer2.userLastName) {
            return 1;
        }

        if (customer1.userLastName === customer2.userLastName) {
            if (customer1.userFirstName < customer2.userFirstName) {
                return -1;
            }

            if (customer1.userFirstName > customer2.userFirstName) {
                return 1;
            }
        }

        return 0;
    });

    return newCustomerArr;
}

const removeCustomer = (customers, facilityCustomerId) => {
    //search for existing cart item , using the find method.
    const existingCustomer = customers.find((customer) => customer.userId === facilityCustomerId);
    if (existingCustomer) {
        return customers.filter(customer => customer.userId !== facilityCustomerId);
    }

    return [...customers];
}

const addMembership = (memberships, membershipToAdd) => {
    //search for existing customer, using the find method.
    const existingMembership = memberships.find((membership) => membership.facilityMembershipId === membershipToAdd.facilityMembershipId);
    if (existingMembership) {
        return memberships;
    }
    //this just creates a new arrray 
    let newMembershipArr = [...memberships, membershipToAdd];

    newMembershipArr.sort((membership1, membership2) => {
        if (membership1.sortOrder < membership2.sortOrder) {
            return -1;
        }

        if (membership1.sortOrder > membership2.sortOrder) {
            return 1;
        }

        return 0;
    });

    return newMembershipArr;
}

const removeMembership = (memberships, facilityMembershipId) => {
    //search for existing cart item , using the find method.
    const existingMembership = memberships.find((membership) => membership.facilityMembershipId === facilityMembershipId);
    if (existingMembership) {
        return memberships.filter(membership => membership.facilityMembershipId !== facilityMembershipId);
    }

    return [...memberships];
}

const addArea = (facilityAreas, areaToAdd) => {
    //search for existing area, using the find method.
    const existingArea = facilityAreas.find((area) => area.id === areaToAdd.id);
    if (existingArea) {
        return facilityAreas;
    }

    let newAreaArr = [...facilityAreas, areaToAdd];
    if (areaToAdd.subAreas) {
        const subAreaIds = Object.keys(areaToAdd.subAreas)
        for (const id of subAreaIds) {
            newAreaArr = newAreaArr.filter((area) => {
                return area.id !== id;
            });
        }
    }

    newAreaArr.sort((area1, area2) => {
        if (area1.sortOrder < area2.sortOrder) {
            return -1;
        }

        if (area1.sortOrder > area2.sortOrder) {
            return 1;
        }

        return 0;
    });

    return newAreaArr;
}

const removeArea = (facilityAreas, facilityAreaToRemove) => {
    //search for existing cart item , using the find method.
    const existing = facilityAreas.find((area) => area.id === facilityAreaToRemove.id);
    if (existing) {
        if (!facilityAreaToRemove.subAreas) {
            return facilityAreas.filter(area => area.id !== facilityAreaToRemove.id);
        } else {
            let newAreaArray = facilityAreas.filter(area => area.id !== facilityAreaToRemove.id);
            const subAreaIds = Object.keys(facilityAreaToRemove.subAreas);
            for (const id of subAreaIds) {
                newAreaArray = newAreaArray.filter(area => area.id !== id);
            }
            return newAreaArray;
        }
    }

    return [...facilityAreas];
}

const findRepeatsUntilMinDate = (startDate, repeatingInterval) => {
    switch (repeatingInterval) {
        case 'Daily':
            return startDate.add(1, 'day');
        case 'Weekly':
            return startDate.add(1, 'week');
        case 'Monthly':
            return startDate.add(1, 'month');
        case 'Yearly':
            return startDate.add(1, 'year');
        default:
            return null;
    }
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const BlackoutCreate = () => {

    const [blackoutInput, setBlackoutInput] = useState(defaultBlackoutInput);
    const { title, selectedAreas, startDate, startTime, endTime, repeatsInterval, repeatsUntilDate, repeatDaysOfWeek, subTitle, reservedForCustomers, reservedForMemberships } = blackoutInput;
    const [openMenu, setOpenMenu] = useState('');
    const [validationMessage, setValidationMessage] = useState(null);
    const [clearHistory, setClearHistory] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useContext(UserContext);
    const { memberships } = useContext(FacilityMembershipsContext);
    const { facilityAreas } = useContext(FacilityAreaContext);
    const [blackoutReasons, setBlackoutReasons] = useState([]);

    const navigate = useNavigate();

    const fetchBlackoutReasons = async (facilityId) => {
        setIsLoading(true);
        const reaasons = await getFacilityBlackoutReasons(facilityId);
        setBlackoutReasons(reaasons);
        setIsLoading(false);
    }

    useEffect(() => {
        if (userData && userData.facilityId) {
            fetchBlackoutReasons(userData.facilityId);
        }
    }, [userData]);

    const showCustomers = () => {
        setOpenMenu('facilitycustomers');
    }

    const showMemberships = () => {
        setOpenMenu('facilitymemberships');
    }

    const closeOpenMenu = () => {
        setOpenMenu('')
    }

    const selectRepeatingDate = (value) => {
        setBlackoutInput({ ...blackoutInput, 'repeatsUntilDate': value });
    }

    const selectRepeatingInterval = (value) => {

        const repeatDate = findRepeatsUntilMinDate(startDate, value);
        setBlackoutInput({ ...blackoutInput, 'repeatsInterval': value, 'repeatsUntilDate': repeatDate });
    }

    const selectBlackoutDate = (value) => {
        setBlackoutInput({ ...blackoutInput, 'startDate': value });
    }
    const selectRepeatingDow = (value) => {
        setBlackoutInput({
            ...blackoutInput, 'repeatDaysOfWeek': value.filter((dow) => {
                return dow !== 'All';
            })
        });
    }

    const returnToCalendar = () => {
        navigate("/facility/calendar");
    }

    const selectFacilityCustomer = (facilityCustomer) => {
        const existingCustomer = reservedForCustomers.find((customer) => customer.userId === facilityCustomer.userId);
        if (existingCustomer) {
            setBlackoutInput({ ...blackoutInput, 'reservedForCustomers': removeCustomer(reservedForCustomers, facilityCustomer.userId) });
        } else {
            setBlackoutInput({ ...blackoutInput, 'reservedForCustomers': addCustomer(reservedForCustomers, facilityCustomer) });
        }
    }

    const removeFacilityCustomer = (facilityCustomerId) => {
        setBlackoutInput({ ...blackoutInput, 'reservedForCustomers': removeCustomer(reservedForCustomers, facilityCustomerId) });
    }

    const clearFacilityCustomers = () => {
        setBlackoutInput({ ...blackoutInput, 'reservedForCustomers': [] });
    }

    const selectFacilityMembership = (facilityMembership) => {
        const existingMembership = reservedForMemberships.find((membership) => membership.facilityMembershipId === facilityMembership.facilityMembershipId);
        if (existingMembership) {
            setBlackoutInput({ ...blackoutInput, 'reservedForMemberships': removeMembership(reservedForMemberships, facilityMembership.facilityMembershipId) });
        } else {
            setBlackoutInput({ ...blackoutInput, 'reservedForMemberships': addMembership(reservedForMemberships, facilityMembership) });
        }
    }

    const removeFacilityMembership = (facilityMembershipId) => {
        setBlackoutInput({ ...blackoutInput, 'reservedForMemberships': removeMembership(reservedForMemberships, facilityMembershipId) });
    }

    const clearAllMemberships = () => {
        setBlackoutInput({ ...blackoutInput, 'reservedForMemberships': [] });
    }

    const selectAllMemberships = () => {
        const membershpsToAdd = [];
        for (const membershipId in memberships) {
            membershpsToAdd.push(memberships[membershipId]);
        }
        setBlackoutInput({ ...blackoutInput, 'reservedForMemberships': membershpsToAdd });
    }

    const isParentAreaInList = (facilityArea) => {
        if (facilityArea.parentAreaId) {
            return selectedAreas.find((area) => area.id === facilityArea.parentAreaId);
        }
        return false;
    }

    const selectFacilityArea = (facilityArea) => {
        const existingFacilityArea = selectedAreas.find((area) => area.id === facilityArea.id);
        if (existingFacilityArea) {
            if (!isParentAreaInList(facilityArea)) {
                setBlackoutInput({ ...blackoutInput, 'selectedAreas': removeArea(selectedAreas, facilityArea) });
            }
        } else {
            setBlackoutInput({ ...blackoutInput, 'selectedAreas': addArea(selectedAreas, facilityArea) });
        }
    }

    const removeFacilityArea = (facilityAreaId) => {
        const facilityAreaToRemove = selectedAreas.find((area) => area.id === facilityAreaId);
        if (facilityAreaToRemove) {
            setBlackoutInput({ ...blackoutInput, 'selectedAreas': removeArea(selectedAreas, facilityAreaToRemove) });
        }
    }

    const clearFacilityAreas = () => {
        setBlackoutInput({ ...blackoutInput, 'selectedAreas': [] });
    }

    const selectAllFacilityAreas = () => {
        setBlackoutInput({ ...blackoutInput, 'selectedAreas': [].concat(facilityAreas) });
    }

    const selectStartTime = (time) => {
        setBlackoutInput({ ...blackoutInput, 'startTime': time });
    }

    const selectEndTime = (time) => {
        setBlackoutInput({ ...blackoutInput, 'endTime': time });
    }

    const getReservationExceptionsDatesList = (dateFormat) => {
        let dates = generateDayJSDatesForReservation(startDate, repeatsUntilDate, repeatDaysOfWeek);
        const datesToReturn = [];
        for (const date of dates) {
            datesToReturn.push(date.format(dateFormat));
        }

        return datesToReturn;
    }

    const getReservedForCustomerIds = () => {
        const customerIds = [];
        for (const customer of reservedForCustomers) {
            customerIds.push(customer.userId);
        }

        return customerIds;
    }

    const getFacilityAreaIds = () => {
        const facilityAreaIds = [];
        for (const sarea of selectedAreas) {
            if (sarea.subAreas) {
                const subAreaIds = Object.keys(sarea.subAreas)
                for (const id of subAreaIds) {
                    facilityAreaIds.push(id);
                }
            }

            facilityAreaIds.push(sarea.id);
        }

        return facilityAreaIds;
    }

    const getReservedForMembershipIds = () => {
        const membershipIds = [];
        for (const membership of reservedForMemberships) {
            membershipIds.push(membership.facilityMembershipId);
        }
        return membershipIds;
    }

    const selectExceptionDates = (datesIn) => {

        const exceptionDates = [];
        for (const stringDate of datesIn) {
            if (stringDate !== "No Exception Dates") {
                exceptionDates.push(dayjs(stringDate).format("YYYY-MM-DD"));
            }
        }

        setBlackoutInput({ ...blackoutInput, 'repeatExceptionDates' : exceptionDates });
    }

    const setTitle = (event) => {
        const { name, value } = event.target;
        setBlackoutInput({ ...blackoutInput, [name]: value });
    }

    const selectBlackoutReason = (reason) => {

        if (reason === 'Clear History') {

            setBlackoutInput({ ...blackoutInput, 'title' : '' });
            setClearHistory(true);
            return;
        }

        if (reason !== 'Reserved') {
            setBlackoutInput({ ...blackoutInput, 'title' : reason, 'reservedForCustomers' : [], 'reservedForMemberships' : [] });
        } else {
            setBlackoutInput({ ...blackoutInput, 'title' : reason });
        }
    }

    const setNotes = (event) => {
        const { name, value } = event.target;
        setBlackoutInput({ ...blackoutInput, [name]: value });
    }

    const closeValidationMessage = () => {
        setValidationMessage(null);
    }

    const showValidationMessage = (message) => {
        setValidationMessage(message);
    }

    const submitBlackoutCreate = async () => {

        //validate 

        if (startTime.isSameOrAfter(endTime)) {
            showValidationMessage('Start Time must be less than End Time.');
            return;
        }

        if (endTime.isSameOrBefore(startTime)) {
            showValidationMessage('End Time must be greater than Start Time. ');
            return;
        }

        if (blackoutInput.selectedAreas.length <= 0) {
            showValidationMessage('You must select at least 1 area to black out.');
            return;
        }

        if (!title) {
            showValidationMessage('You must provide a black out reason.');
            return;
        }

        if (!subTitle) {
            showValidationMessage('You must provide a title.');
            return;
        }

        const existingReason = blackoutReasons.filter((reason) => {
            return reason === title;
        });

        if (!existingReason || existingReason.length <= 0) {
            blackoutReasons.push(title);
            setBlackoutReasons(blackoutReasons);
        }

        //call api to create
        setIsLoading(true)
        const response = await createBlackout(blackoutInput, userData.facilityId);
        const { status, data } = response;
        if (status === 200 && data) {
            const { status, message } = data;
            if (status === 'success') {
                setSuccessMessage(message ? message : "Your blackout was created.")
            } else {
                if (status === 'conflicts_found') {
                    setValidationMessage(message ? message : "Conflicts were found, your blackout was not created.")
                } else {
                    setValidationMessage(message ? message : "Something went wrong, your blackout was not created.")
                }
            }
        }
        setIsLoading(false);
    }

    const clearBlackoutReasonHistory = () => {
        clearFacilityBlackoutReasonHistory(userData.facilityId);
        setClearHistory(false);

        if (userData && userData.facilityId) {
            fetchBlackoutReasons(userData.facilityId);
        }
    }

    const doNotClearBlackoutReasonHistory = () => {
        setClearHistory(false);
    }

    return (
        <BlackoutCreateContainer>
            {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoading &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <Item sx={{ height: 3 / 4 }}>
                                <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                                    {'New Blackout'}
                                </Typography>
                                <BlackoutCreateRow justifycontent={'space-evenly'}>
                                    <TextField fullWidth variant="outlined" label="Title:" type="text" required onChange={setTitle} name="subTitle" value={subTitle} />
                                    <ListWithTextInput variant='outlined' headerText={'Blackout Reason:'} list={blackoutReasons} selectFromList={selectBlackoutReason} selectedItem={title} allowInput={true} ></ListWithTextInput>
                                </BlackoutCreateRow>
                                <DatesRow>
                                    <MaterialUIDatePicker labelText="Blackout Date:" minDate={dayjs().startOf('day')} onChange={selectBlackoutDate} value={startDate}></MaterialUIDatePicker>
                                    <MaterialUITimePicker headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} />
                                    <MaterialUITimePicker headerText="End Time:" views={['hours', 'minutes']} format={'h:mm A'} value={endTime} onChange={selectEndTime} />
                                </DatesRow>
                                <RepeatsRow>
                                    <ListWithTextInput width={4 / 4} variant="outlined" headerText={repeatsInterval ? 'Repeats' : 'Does Not Repeat'} list={REPEATING_INTERVAL_TYPES} selectFromList={selectRepeatingInterval} selectedItem={repeatsInterval}></ListWithTextInput>
                                    {repeatsInterval && repeatsInterval !== 'None' &&
                                        <MaterialUIDatePicker labelText="Repeats Until:" minDate={findRepeatsUntilMinDate(startDate, repeatsInterval)} onChange={selectRepeatingDate} value={repeatsUntilDate}></MaterialUIDatePicker>
                                    }
                                </RepeatsRow>
                                <RepeatsRow>
                                    {
                                        repeatsInterval && (repeatsInterval === 'Weekly' ||
                                            repeatsInterval === 'Monthly' || repeatsInterval === 'Yearly') &&
                                        <MaterialUISelectionList items={DAYS_OF_WEEK_LONG} label={'Days Of Week'} selectItem={selectRepeatingDow} noSelectionText={'All'}></MaterialUISelectionList>
                                    }
                                    {repeatsInterval && repeatsUntilDate &&
                                        <MaterialUISelectionList items={getReservationExceptionsDatesList("dddd, MMMM D, YYYY")} label={'Repeats Exception Dates:'} selectItem={selectExceptionDates} noSelectionText='No Exception Dates'></MaterialUISelectionList>
                                    }
                                </RepeatsRow>
                                {
                                    title === "Reserved" &&
                                    <Item>
                                        <CustomerList header='Reserved For Customers' customers={reservedForCustomers} selectCustomer={showCustomers} removeCustomer={removeFacilityCustomer}></CustomerList>
                                    </Item>
                                }
                                {
                                    title === "Reserved" &&
                                    <Item>
                                        <MembershipList header='Reserved For Memberships' memberships={reservedForMemberships} selectMembership={showMemberships} removeMembership={removeFacilityMembership}></MembershipList>
                                    </Item>
                                }
                            </Item>
                            <Item sx={{ height: 1 / 4, display: 'flex', justifyContent: 'flex-end' }}>
                                <BottomButtonBar okButtonText={'Create Blackout'} okAction={submitBlackoutCreate} cancelButtonText={"Cancel"} cancelAction={returnToCalendar}></BottomButtonBar>
                            </Item>
                        </Grid>
                        <Grid xs={6}>
                            <Item>
                                <FacilityAreas header='Areas To Blackout' selectFacilityArea={selectFacilityArea} selectedFacilityAreas={getFacilityAreaIds()}></FacilityAreas>
                            </Item>
                            <Item>
                                <BottomButtonBar okButtonText={'Select All'} okAction={selectAllFacilityAreas} cancelButtonText={"Clear"} cancelAction={clearFacilityAreas}></BottomButtonBar>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                openMenu === 'blackoutdatecalendar' &&
                <CalendarDatePicker closeCalendar={closeOpenMenu} setDate={selectBlackoutDate} date={startDate} title={'Select Blackout Date'} ></CalendarDatePicker>
            }
            {
                openMenu === 'repeatingresdatecalendar' &&
                <CalendarDatePicker closeCalendar={closeOpenMenu} setDate={selectRepeatingDate} date={repeatsUntilDate ? repeatsUntilDate : new Date()} title={'Select Repeats Until Date'} ></CalendarDatePicker>
            }

            {
                openMenu === 'facilitycustomers' &&
                <FacilityCustomersPicker selectFacilityCustomer={selectFacilityCustomer} selectedCustomers={getReservedForCustomerIds()} closeView={closeOpenMenu} clearCustomers={clearFacilityCustomers} allowMultipleSelection={true} ></FacilityCustomersPicker>
            }

            {
                openMenu == 'facilitymemberships' &&
                <FacilityMembershipsPicker selectMembership={selectFacilityMembership} selectedMemberships={getReservedForMembershipIds()} selectAll={selectAllMemberships} clearAll={clearAllMemberships} closeView={closeOpenMenu}></FacilityMembershipsPicker>
            }
            {validationMessage &&
                <MySportSpaceAlert message={validationMessage} okButtonText={'OK'} okAction={closeValidationMessage}></MySportSpaceAlert>
            }
            {successMessage &&
                <MySportSpaceAlert message={successMessage} okButtonText={'OK'} okAction={returnToCalendar}></MySportSpaceAlert>
            }
            {
                clearHistory &&
                <MySportSpaceAlert message={'Do you want to clear your blackout reasons?'} okButtonText={'Yes'} okAction={clearBlackoutReasonHistory} cancelButtonText={'No'} cancelAction={doNotClearBlackoutReasonHistory}></MySportSpaceAlert>
            }

        </BlackoutCreateContainer>
    )
}

export default BlackoutCreate